/**
 * Styled Pagination Widget
 * A wrapper component for the AIS pagination widget with pre-configured options
 */
// Imports
import { pagination } from 'instantsearch.js/es/widgets';
import { arrowLeftIcon, arrowRightIcon } from '../templates';

// Main Component
export const initPagination = () => {
  return pagination({
    container: '#pagination',
    templates: {
      next: arrowRightIcon(),
      previous: arrowLeftIcon()
      // last: `${data}`
    },
    cssClasses: {
      list: 'c-cluster'
    },
    padding: 2
  });
};

export default initPagination;
