// Types grabbed from https://www.npmjs.com/package/@types/vimeo__player
import Vimeo from '@vimeo/player';

type TVideoController = HTMLVideoElement | Vimeo;

const toggleVideoPlay = (videoController: TVideoController, pause: boolean) => {
  if (pause) {
    videoController.pause();
    return;
  }
  videoController.play();
};

export const initVideoControllers = () => {
  const videoFrames = document.querySelectorAll('.b-videoFrameAutoPlayScroll');
  if (videoFrames && !!window.IntersectionObserver) {
    // Sean: I believe the ratio must be higher than the threshold
    const intersectionRatio = 0.01;

    videoFrames.forEach((frame) => {
      const video = frame.querySelector('video');
      const vimeoIframe: HTMLIFrameElement =
        frame.querySelector('.b-vimeoPlayer');

      if (video || vimeoIframe) {
        // Assign correct videoController
        const videoController: TVideoController = vimeoIframe
          ? (new Vimeo(vimeoIframe) as Vimeo)
          : (video as HTMLVideoElement);
        // const isPaused = vimeoIframe ? videoController.pause : video.pau

        const options = {
          threshold: 0.001
        };

        // Default Callback for regular video El's
        const videoElCallBack: IntersectionObserverCallback = (entries) => {
          entries.forEach((entry: IntersectionObserverEntry) => {
            const pause =
              entry.intersectionRatio <= intersectionRatio && !video.paused;
            toggleVideoPlay(videoController, pause);
          });
        };

        // Callback for vimeo videos contained in iframes
        const vimeoCallBack: IntersectionObserverCallback = (entries) => {
          entries.forEach((entry: IntersectionObserverEntry) => {
            (videoController as Vimeo).getPaused().then(
              (isPaused) => {
                const pause =
                  entry.intersectionRatio <= intersectionRatio && !isPaused;
                toggleVideoPlay(videoController, pause);
              }
              // ,(err) => {
              //   console.log(err);
              // }
            );
          });
        };

        // Assign correct callback
        const callback = vimeoIframe ? vimeoCallBack : videoElCallBack;

        const observer = new IntersectionObserver(callback, options);
        observer.observe(frame);
      }
    });
  }
};

export default initVideoControllers;
