const arrowRightIcon: () => string = () => {
  return /* html */ `
	<svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M0.263672 6.9548H11.9433V9.31266H0.263672V6.9548Z" fill="#002538"/>
		<path d="M7.00059 16.0348L5.39039 14.3833L11.4842 8.13434L5.39039 1.88538L7.00059 0.233887L14.7024 8.13437L7.00059 16.0348Z" fill="#002538"/>
	</svg>`;
};

// eslint-disable-next-line import/prefer-default-export
export { arrowRightIcon };
