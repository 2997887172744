/*
    Meilisearch Parameters
    https://docs.meilisearch.com/reference/api/search.html#body

    Instant Meili Search documentation
    https://www.npmjs.com/package/@meilisearch/instant-meilisearch

    Instantsearch Docs
    https://www.algolia.com/doc/guides/building-search-ui/what-is-instantsearch/js/

    // Example on triggering search and how to access the aloglia search helper
    https://discourse.algolia.com/t/how-to-trigger-instantsearch-query-by-setting-input-value-dynamically/9017/3
    https://codesandbox.io/s/instantsearchjs-app-9re0o?file=/src/app.js:1036-1046

    Accessing State to update
    https://www.algolia.com/doc/guides/building-search-ui/going-further/access-state-outside-lifecycle/js/

    TO DO:
    - Dropdowns global
      - Prevent options from disapperaing from dropdown (Found an option for this but it would require additional work to ensure options are disabled if they would return 0 results)

    - Search Bar
      - Limit options
*/

// #region - Imports
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch from 'instantsearch.js';
import {
  clearRefinements,
  configure,
  currentRefinements,
  hits,
  menu,
  rangeInput,
  rangeSlider,
  refinementList,
  searchBox,
  sortBy,
  stats
} from 'instantsearch.js/es/widgets';
import {
  abbrState,
  flattenArray,
  queryParents,
  TStateFullName
} from '../utils/Index';
import {
  agentListing,
  articleCardTemplate,
  propertyListingFullCard,
  propertyListingHalfCard,
  IAgentListing,
  IArticleCard,
  IPropertyListing,
  initListingDropdowns,
  initMapUI,
  largeScaleLandListingCard,
  ILargeScaleLand,
  multiTractListing,
  IMultiTract,
  landAndRecLicenseTemplate,
  ILandAndRecLicense,
  getStatsTemplate,
  initPagination
} from './listingSearch';
import { initImageFaderMutationObserver } from './ImageFader';
import { initSearchDropdowns } from './SearchBarDropdown';
import { getZipCodeCoordinates } from '../utils/CoordinateLookup';
// #endregion - Imports

// #region - Types
interface IWidgetOptions {
  type: string;
  label: string;
  facet: string;
  placeholder: string;
  options?: string[];
  display: string;
  inputTypes: string[];
  min?: number;
  max?: number;
  showFilter?: boolean;
}

type TNamedWidgetsObject = {
  [key: string]: IWidgetOptions;
};

interface IFilters {
  filters?: string;
  facetFilters?: string | string[];
}

interface IGroupedDropdowns {
  [key: string]: string[];
}

interface ISortOption {
  label: string;
  value: string;
}

type THitsTemplateFunctionName =
  | 'propertyListingHalfCard'
  | 'propertyListingFullCard'
  | 'articleCardTemplate'
  | 'agentListing'
  | 'largeScaleLandListingCard'
  | 'multiTractListing'
  | 'landAndRecLicenseTemplate'
  | 'multiTractProperties';

interface IInstantsearchOptions {
  agentName: string;
  index: string;
  inputs: IWidgetOptions[];
  facets: IWidgetOptions[];
  sortOptions: ISortOption[];
  mapToggle?: boolean;
  multiTractName: string[];
  configuration?: {
    hitsPerPage: number;
  };
  useSortBy?: boolean;
  useStats?: boolean;
  useCurrentRefinements: boolean;
  defaultHitsTemplate: THitsTemplateFunctionName;
  secondaryHitsTemplate: THitsTemplateFunctionName;
  omitPagination: boolean;
  useMap: boolean;
  statsTemplate: string;
}
// #endregion - Types

const getCategoryFacetType = (category: string) => {
  switch (category) {
    case 'timberland':
    case 'recreation':
    case 'development':
    case 'agriculture-farmland':
    case 'acreage-estates':
    case 'conservation':
      return 'propertyTypes';
    case 'active':
    case 'sold':
      return 'propertyStatus';
    default:
      return null;
  }
};

// #region - Widget functions
const resetButton = (container: string, facets: string[]) => {
  const containerName =
    Array.from(container)[0] === '#'
      ? `${container}_resetBtn`
      : `#${container}_resetBtn`;
  return clearRefinements({
    container: containerName,
    includedAttributes: [...facets],
    templates: {
      resetLabel: 'Reset'
    },
    cssClasses: {
      button: ['b-btn', ' b-btn--ghost', ' c-box']
    }
  });
};

// TO DO: Update type for settings for correct type matching, should be able to import from instant search
const styledRefinementList = (
  container: string,
  item: IWidgetOptions,
  settings?: any
) => {
  const widgets: any = [
    refinementList({
      container,
      attribute: item.facet,
      operator: 'or',
      ...settings
    })
  ];

  if (item.display === 'default' && item.type === 'multipleSelect') {
    const resetBtn = resetButton(container, [item.facet]);
    widgets.push(resetBtn);
  }

  return widgets;
};

// HOC Function for getting hits template's depending on type, set within twig listing bases
const getHitsUI = (templateFunctionName: string, targetEl: string) => {
  switch (templateFunctionName) {
    case 'propertyListingHalfCard':
      return hits({
        container: targetEl,
        templates: {
          item(hit) {
            return propertyListingHalfCard(hit as unknown as IPropertyListing);
          }
        }
      });
    case 'propertyListingFullCard':
      return hits({
        container: targetEl,
        templates: {
          item(hit) {
            return propertyListingFullCard(hit as unknown as IPropertyListing);
          }
        }
      });
    case 'articleCardTemplate':
      return hits({
        container: targetEl,
        cssClasses: {
          list: ['| c-autoGrid c-autoGrid--layout-50-50 | u-gutter-800']
        },
        templates: {
          item(hit) {
            return articleCardTemplate(hit as unknown as IArticleCard);
          }
        }
      });
    case 'agentListing':
      return hits({
        container: targetEl,
        templates: {
          item(hit) {
            return agentListing(hit as unknown as IAgentListing);
          }
        }
      });
    case 'foresterListing':
      return hits({
        container: targetEl,
        templates: {
          item(hit) {
            return agentListing(hit as unknown as IAgentListing, true);
          }
        }
      });
    case 'largeScaleLandListingCard':
      return hits({
        container: targetEl,
        cssClasses: {
          list: ['| c-flow c-flow--page']
        },
        templates: {
          item(hit) {
            return largeScaleLandListingCard(hit as unknown as ILargeScaleLand);
          }
        }
      });
    case 'multiTractListing':
      return hits({
        container: targetEl,
        cssClasses: {
          list: ['| c-flow c-flow--page']
        },
        templates: {
          item(hit) {
            return multiTractListing(hit as unknown as IMultiTract);
          }
        }
      });
    case 'landAndRecLicenseTemplate':
      return hits({
        container: targetEl,
        cssClasses: {
          list: ['| c-flow c-flow--page']
        },
        templates: {
          item(hit) {
            return landAndRecLicenseTemplate(
              hit as unknown as ILandAndRecLicense
            );
          }
        }
      });
    default:
      // We can eventually plugin a generic template here that loops through any given object and formats it.
      return hits({
        container: targetEl,
        templates: {
          item() {
            return '';
          }
        }
      });
  }
};

// This function could be improved by switch over the switch statement to look for an input or dropdown type.
const getFacetUIByType = (item: IWidgetOptions, pageCategory?: string) => {
  const container = `#${item.facet}`;
  switch (item.facet) {
    case 'propertyStatus': {
      return menu({
        container,
        attribute: item.facet,
        templates: {
          item(data: any) {
            const { label, url, cssClasses } = data;
            const exception = label.replace(/\s/g, '').toLowerCase();

            return /* html */ `
              <a class="${cssClasses.link}" href="${url}">
                <span
                  class="b-propertyStatus b-propertyStatus--${exception} ${cssClasses.label}"
                  >${label}</span
                >
              </a>
            `;
          }
        }
      });
    }
    case 'price':
    case 'acres': {
      const rangeSliderUI = rangeSlider({
        container: `${container}_${item.inputTypes[0]}`,
        attribute: item.facet,
        pips: false,
        min: item.min,
        max: item.max
      });
      const rangeInputUI = rangeInput({
        container: `${container}_${item.inputTypes[1]}`,
        attribute: item.facet,
        min: item.min,
        max: item.max,
        cssClasses: {
          form: 'c-cluster',
          input: 'c-box',
          submit: 'c-box'
        }
      });
      const resetRange = resetButton(container, [item.facet]);
      return [rangeSliderUI, rangeInputUI, resetRange];
    }
    case 'propertyTypes': {
      const settings = {
        cssClasses: {
          list: 'c-autoGrid',
          item: 'c-box | u-box-flex u-flex-justify-center u-flex-align-center'
        },
        transformItems(items: any) {
          return items.map((facetItem: any) => {
            if (pageCategory === facetItem.value) {
              facetItem.isRefined = true;
              console.log(facetItem);
            }
            return facetItem;
          });
        },
        templates: {
          item(data: any) {
            const { label, isRefined } = data;
            const isAlwaysActive =
              pageCategory === label ? ' b-propertyStatus' : '';
            // convert all '/', ' / ', and ' ' to a dash
            const cleanedLabel = label
              .toLowerCase()
              .replace(/\/| \/ |\s/g, '-');

            return /* html */ `
              <label
                class="ais-RefinementList-label"
                for="${item.facet}-${cleanedLabel}">
                <input
                  id="${item.facet}-${cleanedLabel}"
                  class="ais-RefinementList-checkbox | ${isAlwaysActive}"
                  type="checkbox"
                  value="${label}"
                  checked="${isRefined}"
                  ${
                    pageCategory === label ? 'disabled="disabled"' : ''
                  }> <span class="ais-RefinementList-labelText">${label}</span>
              </label>
            `;
          }
        },
        operator: 'or'
      };
      return styledRefinementList(container, item, settings);
    }
    case 'category':
      return item.showFilter ? styledRefinementList(container, item) : null;
    case 'states':
    case 'state':
      return menu({
        container,
        attribute: item.facet,
        cssClasses: {
          selectedItem: 'u-t-bg-grayscale-200'
        },
        limit: 50,
        transformItems(items) {
          console.log(items);
          if (item.facet === 'states') {
            items.map((menuItem) => {
              menuItem.label = abbrState(menuItem.label as TStateFullName);
              return menuItem;
            });
          }
          items.sort((a, b) => a.label.localeCompare(b.label));
          return items;
        },
        templates: {
          item(data: any) {
            const { label, url, cssClasses } = data;

            return /* html */ `
              <a class="${cssClasses.link}" href="${url}">
                <span
                  class="${cssClasses.label} "
                  >${label}</span
                >
              </a>
            `;
          }
        }
      });
    case 'waterFeatures':
    case 'access':
    case 'improvements':
    case 'views':
    case 'utilities':
    case 'agentName': {
      return styledRefinementList(container, item);
    }
    default:
      return null;
  }
};

// The widgets contained within would be better written in their own module files as they expand
const getWidgets = (
  options: IInstantsearchOptions,
  searchFilters: IFilters | null,
  facetWidgets: any,
  instantsearchInstance: any
) => {
  const {
    index,
    configuration,
    defaultHitsTemplate,
    inputs,
    mapToggle,
    secondaryHitsTemplate,
    sortOptions,
    statsTemplate,
    useCurrentRefinements,
    useSortBy,
    useStats,
    useMap,
    omitPagination
  } = options;
  let widgets = [];

  // Set the initial config outside of configuration conditional so that we can use it as a
  // reset point when updating the config dynamically
  // The state of the configure widget is not replaced entirly so any dynamic values need
  // reset values added here to properly update the configure component when switching
  // between search types.
  const config = searchFilters
    ? {
        ...configuration,
        ...searchFilters
      }
    : {
        ...configuration
      };

  if (configuration) {
    widgets.push(configure(config));
  }
  if (useStats) {
    widgets.push(
      stats({
        container: '#stats',
        templates: {
          text(data) {
            return getStatsTemplate(statsTemplate, data);
          }
        }
      })
    );
  }
  if (mapToggle || useMap) {
    widgets.push(initMapUI(mapToggle));
  }
  if (inputs) {
    initSearchDropdowns({
      target: '.b-searchDropdown--instantsearch',
      instantsearchInstance
    });
    widgets.push(
      searchBox({
        container: '#listingMainSearchBar',
        placeholder: inputs[0].placeholder,
        queryHook(query, search) {
          const searchBarInstantsearchContainer: HTMLElement =
            document.querySelector('#listingMainSearchBar');
          const searchBarDropdown = queryParents(
            searchBarInstantsearchContainer,
            'b-searchDropdown'
          );
          const searchType = searchBarDropdown.dataset.activeSearchtype;
          const { filters } =
            instantsearchInstance.renderState[index].searchBox.widgetParams;

          // Better UX would probably be to ditch singular filters and allow for them to be combined.
          switch (searchType) {
            // case 'searchByLocationName': {
            //   const locationFilters = `states = ${query} OR counties = '${query}' OR county = '${query}' OR address = '${query}' OR zip = '${query}'`;
            //   const updatedFilters = filters
            //     ? `${filters} AND ${locationFilters}`
            //     : locationFilters;
            //   instantsearchInstance.renderState[index].searchBox.refine({
            //     ...config,
            //     filters: updatedFilters
            //   });
            //   break;
            // }
            case 'searchEverything': {
              //query = query.replace(' ','');
              const locationFilters = `states = ${query} OR counties = '${query}' OR county = '${query}' OR address = '${query}' OR zip = '${query}'`;
              const updatedFilters = filters
                ? `${filters} AND ${locationFilters}`
                : locationFilters;
              instantsearchInstance.renderState[index].searchBox.refine({
                ...config,
                filters: updatedFilters
              });
              break;
            }
            case 'searchByState': {
              const stateFilter = `states = '${query}'`;
              const updatedFilters = filters
                ? `${filters} AND ${stateFilter}`
                : stateFilter;
              instantsearchInstance.renderState[index].searchBox.refine({
                ...config,
                filters: updatedFilters
              });
              search(query);
              break;
            }
            // case 'searchByCounty': {
            //   const countyNamesFilter =
            //     query && query !== '' ? `counties = '${query}'` : '';
            //   const updatedFilters = filters
            //     ? `${filters} AND ${countyNamesFilter}`
            //     : countyNamesFilter;
            //   instantsearchInstance.renderState[index].searchBox.refine({
            //     ...config,
            //     filters: updatedFilters
            //   });
            //   search(query);
            //   break;
            // }
            case 'searchPropertiesByTitle': {
              const propertyTitleFilter =
                query && query !== '' ? `title = '${query}'` : '';
              const updatedFilters = filters
                ? `${filters} AND ${propertyTitleFilter}`
                : propertyTitleFilter;
              instantsearchInstance.renderState[index].searchBox.refine({
                ...config,
                filters: updatedFilters
              });
              search(query);
              break;
            }
            case 'searchByZip': {
              const zipFilter = query && query !== '' ? `zip = '${query}'` : '';
              const updatedFilters = filters
                ? `${filters} AND ${zipFilter}`
                : zipFilter;
              instantsearchInstance.renderState[index].searchBox.refine({
                ...config,
                filters: updatedFilters
              });
              search(query);
              break;
            }
              //This snip is for enablnig geoSearch (many zip's are currently returning null)
              getZipCodeCoordinates(query).then((coords) => {
                console.log(coords);
                if (coords) {
                  const { lat, long } = coords;
                  // 200 mile radius (200 * 1609.34) : 1 mile === 1609.34 meters
                  const radiusInMeters = 321868;
                  instantsearchInstance.renderState[index].searchBox.refine({
                    filters: `_geoRadius(${lat}, ${long}, ${radiusInMeters})`
                  });
                  search(query);
                }
              });
            default:
              // instantsearchInstance.renderState[index].searchBox.refine({
              //   ...config,
              //   filters: config.filters ? config.filters : ''
              // });
              //query = query.replace(' ','');
              search(query);
          }
        }
      })
    );
  }
  if (useCurrentRefinements) {
    // Get the default settings for range inputs and assign them to an object
    // Where the key name is the facets name
    const { facets } = options;
    const rangeFacetSettings = facets.filter((facetObj) => {
      const { type } = facetObj;
      return type === 'range';
    });

    const rangeInputs: TNamedWidgetsObject = rangeFacetSettings.reduce(
      (a, rangeFacet) => ({ ...a, [rangeFacet.facet]: rangeFacet }),
      {}
    );

    widgets.push(
      currentRefinements({
        container: '#refinements',
        cssClasses: {
          list: 'c-cluster',
          item: 'c-cluster',
          category: 'c-cluster'
        },
        transformItems(items) {
          // Check range input types for default values
          const filteredItems = items.filter((item) => {
            const { attribute } = item;

            if (attribute === 'price' || attribute === 'acres') {
              const { refinements } = item;
              const filteredRanges = refinements.filter((refinement) => {
                const { value } = refinement;
                const defaultSettings: IWidgetOptions = rangeInputs[attribute];
                const { min, max } = defaultSettings;
                // return value !== min && value !== max;
                // Check value agaisnt default
                if (value === min || value === max) {
                  return false;
                }
                return true;
              });
              item.refinements = filteredRanges;
              return filteredRanges.length > 0;
            }

            return true;
          });

          filteredItems.map((item) => {
            const { attribute } = item;
            if (attribute === 'price') {
              const { refinements } = item;
              refinements.map((refinement) => {
                const pieces = refinement.label.split(' ');
                refinement.label = `${pieces[0]} $${pieces[1]}`;
                return refinement;
              });
              item.refinements = refinements;
            }
            return item;
          });
          return filteredItems;
        }
      })
    );
  }
  if (defaultHitsTemplate) {
    const hitWidget = getHitsUI(defaultHitsTemplate, '#listings');
    widgets.push(hitWidget);

    if (!omitPagination) {
      widgets.push(initPagination());
    }
  }
  if (secondaryHitsTemplate) {
    const hitWidget = getHitsUI(secondaryHitsTemplate, '#listingsFull');
    widgets.push(hitWidget);
  }
  if (useSortBy) {
    widgets.push(
      sortBy({
        container: '#sortBy',
        items: sortOptions
      })
    );
  }
  if (facetWidgets.length > 0) {
    widgets = [...widgets, ...facetWidgets];
  }

  return widgets;
};
// #endregion - Widget functions

const initListingUI = () => {
  // Get form data
  const INSTANT_SEARCH_RULES_EL: HTMLElement = document.querySelector(
    '[data-instantsearch-rules]'
  );
  if (INSTANT_SEARCH_RULES_EL) {
    initListingDropdowns();
    const searchClient = instantMeiliSearch(
      process.env.MEILI_FRONTEND_HOST,
      process.env.MEILI_API_KEY,
      {
        finitePagination: true,
        keepZeroFacets: true
        // matchingStrategy: 'all' <- Sean: is outlined in docs but isn't in code base
      }
    );
    const INSTANT_SEARCH_RULES_DATA = JSON.parse(
      INSTANT_SEARCH_RULES_EL.dataset.instantsearchRules
    );

    const {
      agentName,
      multiTractName,
      filters,
      category,
      facets = []
    } = INSTANT_SEARCH_RULES_DATA;

    // #region - Category Page default values
    /*
    {
      filters: '${searchParameter}:${value}'
    }
     */
    const searchFilters = filters || {};

    // Property Category Pages
    const categoryFormatted = category
      ? category.toLowerCase().replace(/\//g, '').split(' ').join('-')
      : null;
    const pageCategory = categoryFormatted;
    const categoryFacetType = getCategoryFacetType(pageCategory);
    if (categoryFacetType && pageCategory) {
      searchFilters.facetFilters = `${categoryFacetType}:${pageCategory}`;
    }

    // Agent Entry Pages
    if (agentName) {
      searchFilters.facetFilters = `agentName:${agentName}`;
    }

    // Multi-tract Entry Pages
    if (multiTractName) {
      searchFilters.facetFilters = `multiTractId:${multiTractName}`;
    }
    // #endregion - Category Page default values

    // #region Grouped dropdowns - Ex: more dropdown in property listing
    const groupedDropdowns: IGroupedDropdowns = {};
    let facetWidgets = facets.map((item: IWidgetOptions) => {
      // In the future we should shift the facets data to match the desired UI
      if (item.display !== 'default') {
        const displayKeyExists = groupedDropdowns[item.display as keyof Object];
        if (!displayKeyExists) {
          // If object doesn't exist
          groupedDropdowns[item.display as keyof Object] = [item.facet];
        } else {
          // If display key exists
          groupedDropdowns[item.display as keyof Object].push(item.facet);
        }
      }
      return getFacetUIByType(item, category);
    });

    const groupedDropdownKeys = Object.keys(groupedDropdowns);
    groupedDropdownKeys.forEach((key) => {
      const includedAttributes = groupedDropdowns[key];
      facetWidgets.push(resetButton(key, includedAttributes));
    });
    // #endregion - Grouped Dropdowns

    // Remove nulls & flatten widgets array before spreading onto search.addWidgets()
    facetWidgets = facetWidgets.filter(Boolean);
    facetWidgets = flattenArray(facetWidgets);

    const instantsearchInstance = instantsearch({
      indexName: INSTANT_SEARCH_RULES_DATA.index,
      searchClient,
      routing: true
    });

    const widgets = getWidgets(
      INSTANT_SEARCH_RULES_DATA,
      searchFilters,
      facetWidgets,
      instantsearchInstance
    );

    instantsearchInstance.addWidgets(widgets);
    instantsearchInstance.start();

    initImageFaderMutationObserver('#listings');
  }
};

export default initListingUI;
