const moveFirstElToLast = (e: Event) => {
  const target = e.target as Element;
  target.parentNode.appendChild(target.previousElementSibling);
};

export const imageFaderTemplate = (images: string[]) => {
  const imageSlideTemplate = (isLayer: boolean, image: string) => {
    const layerClass = isLayer ? ' c-layer' : '';
    return /* html */ `
        <li class='b-imageFader__imageSlide | c-frame${layerClass}'>
          <img src='${image}' />
        </li>
      `;
  };

  const previewImages = images.slice(0, 4);

  return /* html */ `
  <ul class='b-imageFader' data-image-fader>
    ${previewImages
      .map((image, index) => {
        const isLayer = index !== 0;
        return imageSlideTemplate(isLayer, image);
      })
      .join('')}
  </ul>`;
};

export function initImageFader() {
  const imageFaders = document.querySelectorAll('[data-image-fader]');

  if (!imageFaders.length) return;

  imageFaders.forEach((faderEl: HTMLElement) => {
    faderEl.querySelectorAll('.b-imageFader > li').forEach((slide) => {
      slide.addEventListener('animationend', moveFirstElToLast);
    });
  });
}

export const unmountImageFaders = () => {
  const imageFaders = document.querySelectorAll('[data-image-fader]');

  if (!imageFaders.length) return;

  imageFaders.forEach((faderEl: HTMLElement) => {
    faderEl
      .querySelectorAll('.b-imageFader > li')
      .forEach((slide) =>
        slide.removeEventListener('animationend', moveFirstElToLast)
      );
  });
};

export const initImageFaderMutationObserver = (targetEl: string) => {
  const callback = (mutationList: MutationRecord[]) => {
    mutationList.forEach((mutation: MutationRecord) => {
      const { target } = mutation;
      const el = target as HTMLElement;
      const isFaderChange = el.classList.contains('b-imageFader');
      if (!isFaderChange) {
        unmountImageFaders();
        initImageFader();
      }
    });
  };

  const targetNode = document.querySelector(targetEl);

  if (targetNode) {
    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }

  // return observer;
};

export default initImageFader;
