const initMapButton = () => {
  const mapButtons = document.querySelectorAll('.directions-button');
  mapButtons.forEach((button: HTMLElement) => {
    button.addEventListener('click', () => {
      const { lat, lng, name } = button.dataset;
      if (
        navigator.platform.indexOf('iPhone') !== -1 ||
        navigator.platform.indexOf('iPod') !== -1 ||
        navigator.platform.indexOf('iPad') !== -1
      ) {
        window.open(`maps://www.maps.apple.com/?q=${name}&ll=${lat},${lng}`);
      } else {
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
        );
      }
    });
  });
};
export default initMapButton;
