import { buttonTemplate, styledPriceTemplate } from '../templates';
import { propertyFaderTemplate } from './PropertyFader';

export interface IPropertyListing {
  id: string;
  acres: number;
  address: string;
  agentName: string;
  agentUrl: string;
  saleType: string;
  formattedPrice: string;
  formattedPricePerAcre: string;
  propertyPhotos: string[];
  propertyStatus: string;
  propertyTypes: string[];
  title: string;
  url: string;
}

export const listingTitle = (text: string, className?: string) => {
  return /* html */ `
    <h3 class='b-listingTitle | u-t-color-core-secondary ${className}'>${text}</h3>
  `;
};

export const acresTemplate = (acres: number) => {
  if (!acres) {
    return '';
  }

  const acreString = acres.toLocaleString('en-US');

  return /* html */ `
  <p>
    <span class='u-color-secondary-box-text'>&#177;&nbsp;</span>${acreString} Acres
  </p>
  `;
};

const perAcreTemplate = (formattedPricePerAcre: string) => {
  if (!formattedPricePerAcre || formattedPricePerAcre === '0') return '';

  return /* html */ `
  <p class="b-styledPrice">
    <sup class='b-styledPrice__dollarSign'>or $</sup>
    ${formattedPricePerAcre}/acre
  </p>
  `;
};

const propertyStatusTemplate = (propertyStatus: string) => {
  const propertyStatusPieces = propertyStatus.split(' ');
  const propertyStatusClassExtension = propertyStatusPieces.join('');
  return /* html */ `
  <p class='b-propertyStatus b-propertyStatus--${propertyStatusClassExtension}'>${propertyStatus}</p>
  `;
};

const propertyTypesTemplate = (types: string[]) => {
  const propertyTypes = types
    .map((type: string) => {
      return type;
    })
    .join(', ');

  const propertyTypesDisplay = /* html */ `<p class='b-propertyListing__types'>${propertyTypes}</p>`;

  return propertyTypesDisplay;
};

const listingAgentTemplate = (agentName: string, agentUrl: string) => {
  return /* html */ `
  <p class=''>Listing Agent: <span class='u-t-color-core-secondary'><a href="${agentUrl}">${agentName}</a></span></p>`;
};

export const checkPropertyPhotos = (propertyPhotos: string[]) => {
  return !propertyPhotos || propertyPhotos.length === 0
    ? ['/dist/img/re-profile-default.png']
    : propertyPhotos;
};

export const propertyListingHalfCard: (data: IPropertyListing) => string = ({
  id,
  acres,
  address,
  agentName,
  agentUrl,
  propertyPhotos,
  saleType,
  formattedPrice,
  propertyStatus,
  propertyTypes,
  title,
  url
}) => {
  const photos = checkPropertyPhotos(propertyPhotos);

  return /* html */ `
  <div class='b-propertyListing | c-cluster' tabindex='0' data-listing-id="${id}">
    ${propertyFaderTemplate(photos, id, url)}
    <div class='b-propertyListing__content'>
      <a href='${url}'>
        ${listingTitle(title)}
      </a>
      <div class='c-repel'>
        <p class='b-propertyListing__address'>${address}</p>
        ${styledPriceTemplate(formattedPrice, saleType)}
      </div>
      <div class='c-repel'>
        ${acresTemplate(acres)}
        ${propertyStatusTemplate(propertyStatus)}
      </div>
      ${listingAgentTemplate(agentName, agentUrl)}
      ${propertyTypesTemplate(propertyTypes)}
    </div>
  </div>
  `;
};

export const propertyListingFullCard: (data: IPropertyListing) => string = ({
  id,
  acres,
  address,
  agentName,
  agentUrl,
  propertyPhotos,
  saleType,
  formattedPrice,
  formattedPricePerAcre,
  propertyStatus,
  propertyTypes,
  title,
  url
}) => {
  const photos = checkPropertyPhotos(propertyPhotos);

  return /* html */ `
  <div class='b-propertyListing b-propertyListing--full | c-cluster' data-listing-id="${id}">
    ${propertyFaderTemplate(photos, id)}
    <div class='c-flow'>
      <div class='c-flow'>
        ${listingTitle(title)}
        <p class='propertyListing__address'>${address}</p>
      </div>
      ${acresTemplate(acres)}
      ${propertyStatusTemplate(propertyStatus)}
    </div>
    <div class='c-flow'>
      ${listingAgentTemplate(agentName, agentUrl)}
      ${propertyTypesTemplate(propertyTypes)}
    </div>
    <div class='c-flow'>
      <div class='u-ta-right'>
        ${styledPriceTemplate(formattedPrice, saleType)}
        ${perAcreTemplate(formattedPricePerAcre)}
      </div>
      ${buttonTemplate({
        className: 'b-button--secondary',
        content: 'Learn More',
        href: url
      })}
    </div>
  </div>
  `;
};

export default propertyListingHalfCard;
