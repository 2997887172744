import { spriteIcon } from '../templates';

export interface IArticleCard {
  image: string;
  publishedDate: string;
  teaserText: string;
  title: string;
  url: string;
}

export const articleCardTemplate: (data: IArticleCard) => string = ({
  image,
  publishedDate,
  teaserText,
  title,
  url
}) => {
  // Anchor tag can be pulled for a button template if also needed in the agents
  if (image) {
    return /* html */ `
    <article class='b-articleCard'>
      <div class='c-frame c-withLayer'>
        <img src='${image}' />
        <div class='c-layer'>
          <div class='hoverBar hoverBar--top'></div>
        </div>
      </div>
      <div class='c-box'>
        <div class='c-flow'>
          <p class='eyebrow | u-t-color-grayscale-500 u-tt-upper u-weight-extra-bold u-text-0'>
            <time>${publishedDate}</time>
          </p>
          <h3 class='headline | u-t-color-core-primary'>${title}</h3>
          <p class='u-t-color-core-tertiary'>${teaserText}</p>
          <div>
            <a href='${url}' class='b-button  b-button--wipe'>
              ${spriteIcon('arrow-right')}
              <span class='b-button__text'>Read More</span>
            </a>
          </div>
        </div>
      </div>
    </article>
  `;
  } else {
    return /* html */ `
    <article class='b-articleCard'>
      <div class='c-box'>
        <div class='c-flow'>
          <p class='eyebrow | u-t-color-grayscale-500 u-tt-upper u-weight-extra-bold u-text-0'>
            <time>${publishedDate}</time>
          </p>
          <h3 class='headline | u-t-color-core-primary'>${title}</h3>
          <p class='u-t-color-core-tertiary'>${teaserText}</p>
          <div>
            <a href='${url}' class='b-button  b-button--wipe'>
              ${spriteIcon('arrow-right')}
              <span class='b-button__text'>Read More</span>
            </a>
          </div>
        </div>
      </div>
    </article>
  `;
  }
};

export default articleCardTemplate;
