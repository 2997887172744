const initModal = () => {
  const body: HTMLBodyElement = document.querySelector('body');
  const modals = document.querySelectorAll('.b-modal');

  if (modals) {
    Array.from(modals).forEach((modal) => {
      const closeBtn = document.getElementById('modal-close');
      const cancelBtn = modal.querySelector('.b-cancelBtn');
      const shadowBox = modal.querySelector('.c-layer--shadowbox');
      const showBtns = document.querySelectorAll('[data-target-modal]');

      const closeModal = () => {
        modal.classList.remove('b-modal--open');
        body.style.overflow = 'auto';
      };

      closeBtn.addEventListener('click', closeModal);

      if (cancelBtn) {
        cancelBtn.addEventListener('click', closeModal);
      }

      if (shadowBox) {
        shadowBox.addEventListener('click', closeModal);
      }

      if (showBtns && showBtns.length > 0) {
        showBtns.forEach((button) => {
          button.addEventListener('click', (e) => {
            const btn = e.target as HTMLElement;
            // This should probably swap out content on a singular modal
            const targetModal = document.querySelector(
              `[data-modal-name='${btn.dataset.targetModal}']`
            );
            targetModal.classList.add('b-modal--open');
            body.style.overflow = 'hidden';
          });
        });
      }
    }     
  )}; 
}

export default initModal;
