import { buttonTemplate } from '../templates';
import { acresTemplate, listingTitle } from './PropertyListingTemplates';

export interface ILandAndRecLicense {
  acres: number;
  url: string;
  location: string;
  status: string;
  title: string;
  usage: string[];
}

export const statusTemplate = (status: string) => {
  if (!status) {
    return '';
  }

  const statusException = status.split('').join('').toLowerCase();
  return /* html */ `
  <p class='c-cluster'>
    <span class='b-statusIndicator b-statusIndicator--${statusException}'></span>
    <span>${status}</span>
  </p>`;
};

const usageTemplate = (usage: string[]) => {
  if (!usage || usage.length === 0) {
    return '';
  }

  const items = usage.map((item, index) => {
    const space = index !== 0 ? '&nbsp;' : '';
    return /* html */ `${space}${item}`;
  });

  return /* html */ `
  <div>
    <p class='u-weight-semi-bold'>Usage</p>
    <p>${items}</p>
  </div>`;
};

export const landAndRecLicenseTemplate: (
  data: ILandAndRecLicense
) => string = ({ acres, url, location, status, title, usage }) => {
  return /* html */ `
  <div class='b-landAndRecLicense | c-switcher'>
      <div class='c-flow u-measure-short'>
        <a href="`+url+`">${listingTitle(title)}</a>
        <p>${location}</p>
      </div>
      <div class='c-flow'>
        ${statusTemplate(status)}
        ${acresTemplate(acres)}
      </div>
      <div class='u-box-flex u-flex-justify-between u-flex-align-center' style="align-items: flex-end;">
        <div>
          ${usageTemplate(usage)}
        </div>
        ${buttonTemplate({
          className: 'b-button--secondary',
          content: 'View Details',
          href: url
        })}
      </div>
  </div>`;
};

export default landAndRecLicenseTemplate;
