// Mapping
const mapContainer = document.getElementById('agentmap');

// Degrees / Education
const addDegree = document.getElementById('addDegree');
const degreeContainer = document.getElementById('degreeContainer');

// Custom Links
const addCustomLink = document.getElementById('addCustomLink');
const customLinkContainer = document.getElementById('customLinkContainer');

// Broker In Charge
const addBrokerInCharge = document.getElementById('addBrokerInCharge');
const brokerInChargeContainer = document.getElementById(
  'brokerInChargeContainer'
);

// Testimonials
const addTestimonial = document.getElementById('addTestimonialLink');
const testimonialContainer = document.getElementById('testimonialContainer');

// Licenses
const addLicense = document.getElementById('addLicenseLink');

const removeIcon = `<svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="22" height="28" viewBox="0 0 22 28">
<path fill="#862E2B" d="M8 11.5v9c0 0.281-0.219 0.5-0.5 0.5h-1c-0.281 0-0.5-0.219-0.5-0.5v-9c0-0.281 0.219-0.5 0.5-0.5h1c0.281 0 0.5 0.219 0.5 0.5zM12 11.5v9c0 0.281-0.219 0.5-0.5 0.5h-1c-0.281 0-0.5-0.219-0.5-0.5v-9c0-0.281 0.219-0.5 0.5-0.5h1c0.281 0 0.5 0.219 0.5 0.5zM16 11.5v9c0 0.281-0.219 0.5-0.5 0.5h-1c-0.281 0-0.5-0.219-0.5-0.5v-9c0-0.281 0.219-0.5 0.5-0.5h1c0.281 0 0.5 0.219 0.5 0.5zM18 22.813v-14.812h-14v14.812c0 0.75 0.422 1.188 0.5 1.188h13c0.078 0 0.5-0.438 0.5-1.188zM7.5 6h7l-0.75-1.828c-0.047-0.063-0.187-0.156-0.266-0.172h-4.953c-0.094 0.016-0.219 0.109-0.266 0.172zM22 6.5v1c0 0.281-0.219 0.5-0.5 0.5h-1.5v14.812c0 1.719-1.125 3.187-2.5 3.187h-13c-1.375 0-2.5-1.406-2.5-3.125v-14.875h-1.5c-0.281 0-0.5-0.219-0.5-0.5v-1c0-0.281 0.219-0.5 0.5-0.5h4.828l1.094-2.609c0.313-0.766 1.25-1.391 2.078-1.391h5c0.828 0 1.766 0.625 2.078 1.391l1.094 2.609h4.828c0.281 0 0.5 0.219 0.5 0.5z"/>
</svg>`;

// Generate ID for custom fields
function generateID() {
  return (Math.random() + 1).toString(36).substring(7);
}

// Remove Row
function initRemoveButtons(selector: string, parentContainer: HTMLElement) {
  const removeButtons = document.querySelectorAll(selector);

  removeButtons.forEach((removeBtn) => {
    removeBtn.addEventListener('click', () => {
      const container = document.getElementById(
        removeBtn.getAttribute('data-id')
      );
      parentContainer.removeChild(container);
    });
  });
}

function initRemoveRow(selector: string) {
  const removeButtons = document.querySelectorAll(selector);

  removeButtons.forEach((removeBtn) => {
    removeBtn.addEventListener('click', () => {
      const container = document.getElementById(
        removeBtn.getAttribute('data-id')
      );
      container.remove();
    });
  });
}

// map
if (mapContainer) {
  // Initialize the map
  var map = new google.maps.Map(mapContainer, {
    center: { lat: 35.2270869, lng: -80.8431267 },
    zoom: 12
  });

  // Initialize the Places service
  var placesService = new google.maps.places.PlacesService(map);

  // Create a search bar
  var locationInput = document.getElementById('locationInput');
  var autocomplete = new google.maps.places.Autocomplete(locationInput as HTMLInputElement);
  var searchInput = document.getElementById('locationInput') as HTMLInputElement | null;
  var latitudeInput = document.getElementById('lat') as HTMLInputElement | null;
  var longitudeInput = document.getElementById('lng') as HTMLInputElement | null;
  var numberInput = document.getElementById('number') as HTMLInputElement | null;
  var addressInput = document.getElementById('address') as HTMLInputElement | null;
  var cityInput = document.getElementById('city') as HTMLInputElement | null;
  var postcodeInput = document.getElementById('postcode') as HTMLInputElement | null;
  var stateInput = document.getElementById('state') as HTMLInputElement | null;
  var countyInput = document.getElementById('county') as HTMLInputElement | null;
  var countryInput = document.getElementById('country') as HTMLInputElement | null;
      
  var lat = parseFloat(latitudeInput.value);
  var lng = parseFloat(longitudeInput.value);

  var marker: google.maps.Marker; // Declare marker with the correct type

  // Function to perform reverse geocoding and update address fields
  function reverseGeocodeAndUpdateFields(
    latLng: google.maps.LatLng,
    searchInput: HTMLInputElement | null,
    addressInput: HTMLInputElement | null,
    cityInput: HTMLInputElement | null,
    stateInput: HTMLInputElement | null,
    postcodeInput: HTMLInputElement | null,
    numberInput: HTMLInputElement | null,
    countyInput: HTMLInputElement | null,
    countryInput: HTMLInputElement | null
  ) {
    const geocoder = new google.maps.Geocoder();

    geocoder.geocode({ location: latLng }, (results, status) => {
      if (status === google.maps.GeocoderStatus.OK && results[0]) {
        const addressDetails = results[0];
        // Populate the search input with the address
        searchInput.value = addressDetails.formatted_address;
        for (const component of addressDetails.address_components) {
          if (component.types.includes("street_number")) {
            if (numberInput) {
              numberInput.value = component.long_name;
            }
          } else if (component.types.includes("route")) {
            if (addressInput) {
              addressInput.value = component.long_name;
            }
          } else if (component.types.includes("locality")) {
            if (cityInput) {
              cityInput.value = component.long_name;
            }
          } else if (
            component.types.includes("administrative_area_level_1")
          ) {
            if (stateInput) {
              stateInput.value = component.short_name;
            }
          } else if (component.types.includes("postal_code")) {
            if (postcodeInput) {
              postcodeInput.value = component.long_name;
            }
          } else if (component.types.includes("administrative_area_level_2")) {
            if (countyInput) {
              countyInput.value = component.long_name;
            }
          } else if (component.types.includes("country")) {
            if (countryInput) {
              countryInput.value = component.long_name;
            }
          }
        }
      }
    });
  }
  
  // Check if the input fields have existing values
  if (!isNaN(lat) && !isNaN(lng)) {
    var existingLocation = new google.maps.LatLng(lat, lng);

    // Center the map on the existing location
    map.setCenter(existingLocation);

    // Add a draggable marker for the existing location
    marker = new google.maps.Marker({
        map: map,
        position: existingLocation,
        draggable: true
    });

    // Update input fields when the marker is dragged and dropped
    marker.addListener("dragend", function (
      event: google.maps.MouseEvent
    ) {
      latitudeInput.value = event.latLng.lat().toString();
      longitudeInput.value = event.latLng.lng().toString();
    
      // Call the reverseGeocodeAndUpdateFields function to update address fields
      reverseGeocodeAndUpdateFields(
        event.latLng,
        searchInput,
        addressInput,
        cityInput,
        stateInput,
        postcodeInput,
        numberInput,
        countyInput,
        countryInput
      );
    });
  } else {
    // If no existing location, create a marker that can be moved
    marker = new google.maps.Marker({
        map: map,
        position: map.getCenter(),
        draggable: true
    });

    // Update input fields when the marker is dragged and dropped
    marker.addListener("dragend", function (
      event: google.maps.MouseEvent
    ) {
      latitudeInput.value = event.latLng.lat().toString();
      longitudeInput.value = event.latLng.lng().toString();
    
      // Call the reverseGeocodeAndUpdateFields function to update address fields
      reverseGeocodeAndUpdateFields(
        event.latLng,
        searchInput,
        addressInput,
        cityInput,
        stateInput,
        postcodeInput,
        numberInput,
        countyInput,
        countryInput
      );
    });
  }

  // Listen for place selection
  autocomplete.addListener('place_changed', function () {
    var place = autocomplete.getPlace();

    if (place.geometry) {
      var latitudeInput = document.getElementById('property-latitude') as HTMLInputElement | null;
      var longitudeInput = document.getElementById('property-longitude') as HTMLInputElement | null;
      
      // Check if the elements were found before setting their values
      if (latitudeInput && longitudeInput) {
          // Update the input fields with the selected location's latitude and longitude
          latitudeInput.value = place.geometry.location.lat().toString();
          longitudeInput.value = place.geometry.location.lng().toString();
      }
      // Center the map on the selected location
      map.setCenter(place.geometry.location);
      // Set the marker to the selected location
      marker.setPosition(place.geometry.location);

      // Call the reverseGeocodeAndUpdateFields function to update address fields
      reverseGeocodeAndUpdateFields(
        place.geometry.location,
        searchInput,
        addressInput,
        cityInput,
        stateInput,
        postcodeInput,
        numberInput,
        countyInput,
        countryInput
      );
    }
  });
};

export default function init() {
  // Initialize all remove buttons
  initRemoveButtons('.remove-degree', degreeContainer);
  initRemoveButtons('.remove-customLinks', customLinkContainer);
  initRemoveButtons('.remove-brokerInCharge', brokerInChargeContainer);
  initRemoveButtons('.remove-testimonial', testimonialContainer);
  initRemoveRow('.remove-license');

  if (addDegree) {
    addDegree.addEventListener('click', () => {
      // Generate ID
      const id = generateID();
      // Degrees / Education
      const inputContainer = document.createElement('div');

      inputContainer.id = `inputContainer_${id}`;
      inputContainer.className = 'Grid';
      inputContainer.innerHTML = `<div class="text"><label for="degree_${id}">Degree</label><input id="degree_${id}" type='text' name='degree[]'></div>
    <button class="Button-remove Button-left remove-degree" type="button" data-id="inputContainer_${id}">${removeIcon}</button>`;

      degreeContainer.appendChild(inputContainer);
      initRemoveButtons('.remove-degree', degreeContainer);
    });
  }

  if (addCustomLink) {
    addCustomLink.addEventListener('click', () => {
      // Generate ID
      const id = generateID();
      // Custom Links
      const inputLinksContainer = document.createElement('div');

      inputLinksContainer.id = `inputLinksContainer_${id}`;
      inputLinksContainer.className = 'Grid';
      inputLinksContainer.innerHTML = `<div class="text"><label for="customLinkText_${id}">Link Display Text</label><input id="customLinkText_${id}" type='text' name='customLink[${id}][linkDisplayText]'></div>
      <div class="text"><label for="customLinkUrl_${id}">URL</label><input id="customLinkUrl_${id}" type='text' name='customLink[${id}][linkUrl]'></div>
      <button class="Button-remove Button-left remove-customLinks" type="button" data-id="inputLinksContainer_${id}">${removeIcon}</button>`;

      customLinkContainer.appendChild(inputLinksContainer);
      initRemoveButtons('.remove-customLinks', customLinkContainer);
    });
  }

  if (addBrokerInCharge) {
    addBrokerInCharge.addEventListener('click', () => {
      // Generate ID
      const id = generateID();
      // Broker In Charge
      const inputBrokerContainer = document.createElement('div');
      const states = JSON.parse(
        (<HTMLInputElement>document.getElementById('states')).value
      );
      const users = JSON.parse(
        (<HTMLInputElement>document.getElementById('users')).value
      );
      // Dropdown
      let statesDropdown = `<div class="dropdown"><label for="bicState_${id}">BIC State</label><select name="brokerInCharge[${id}][bicState]" id="bicState_${id}">`;
      let usersDropdown = `<div class="dropdown"><label for="brokerInCharge_${id}">Broker In Charge</label><select name="brokerInCharge[${id}][brokerInCharge]" id="brokerInCharge_${id}">`;

      states.forEach((state: any) => {
        statesDropdown += `<option value="${state.id}">${state.title}</option>`;
      });

      users.forEach((user: any) => {
        usersDropdown += `<option value="${user.id}">${user.title}</option>`;
      });

      statesDropdown += '</select></div>';
      usersDropdown += '</select></div>';

      inputBrokerContainer.id = `inputBrokerContainer_${id}`;
      inputBrokerContainer.className = 'Grid';
      inputBrokerContainer.innerHTML = `${statesDropdown}<div class="text"><label for="bicName_${id}">BIC Name</label><input id="bicName_${id}" type='text' name='brokerInCharge[${id}][bicName]'></div>
      <div class="text"><label for="bicStateAbbreviation_${id}">BIC State Abbreviation</label><input id="bicStateAbbreviation_${id}" type='text' name='brokerInCharge[${id}][bicStateAbbreviation]'></div>${usersDropdown}
      <button class="Button-remove Button-left remove-brokerInCharge" type="button" data-id="inputBrokerContainer_${id}">${removeIcon}</button>`;

      brokerInChargeContainer.appendChild(inputBrokerContainer);
      initRemoveButtons('.remove-brokerInCharge', brokerInChargeContainer);
    });
  }

  if (addTestimonial) {
    addTestimonial.addEventListener('click', () => {
      // Generate ID
      const id = generateID();
      // Custom Links
      const testimonialLinksContainer = document.createElement('div');

      testimonialLinksContainer.id = `inputTestimonialContainer_${id}`;
      testimonialLinksContainer.className = 'Grid';
      testimonialLinksContainer.innerHTML = `<div class="text"><label for="testimonialQuote_${id}">Testimonial Quote</label><input id="testimonialQuote_${id}" type='text' name='testimonial[${id}][testimonialQuote]'></div>
      <div class="text"><label for="testimonialSource_${id}">Testimonial Source</label><input id="testimonialSource_${id}" type='text' name='testimonial[${id}][testimonialSource]'></div>
      <button class="Button-remove Button-left remove-testimonial" type="button" data-id="inputTestimonialContainer_${id}">${removeIcon}</button>`;

      testimonialContainer.appendChild(testimonialLinksContainer);
      initRemoveButtons('.remove-testimonial', testimonialContainer);
    });
  }

  if (addLicense) {
    addLicense.addEventListener('click', () => {
      // Generate ID
      const id = generateID();
      const tableRef = document
        .getElementById('licenseData')
        .getElementsByTagName('tbody')[0];

      const newRow = tableRef.insertRow(tableRef.rows.length);
      const states = JSON.parse(
        (<HTMLInputElement>document.getElementById('states')).value
      );
      const users = JSON.parse(
        (<HTMLInputElement>document.getElementById('users')).value
      );
      const licenseType = JSON.parse(
        (<HTMLInputElement>document.getElementById('licenseType')).value
      );
      // Dropdown
      let statesDropdown = `<fieldset class="c-flow c-withLayer"><label for="licenseState_${id}">State</label><div class="dropdown"><select name="licenses[${id}][state]" id="licenseState_${id}">`;
      let usersDropdown = `<fieldset class="c-flow c-withLayer"><label for="licenseBrokerInCharge_${id}">Broker In Charge</label><div class="dropdown"><select name="licenses[${id}][brokerInCharge]" id="licenseBrokerInCharge_${id}">`;
      let licenseDropdown = `<fieldset class="c-flow c-withLayer"><label for="licenseType_${id}">License Type</label><div class="dropdown"><select name="licenses[${id}][licenseType]" id="licenseType_${id}">`;

      states.forEach((state: any) => {
        statesDropdown += `<option value="${state.id}">${state.title}</option>`;
      });

      users.forEach((user: any) => {
        usersDropdown += `<option value="${user.id}">${user.title}</option>`;
      });

      licenseType.forEach((license: any) => {
        licenseDropdown += `<option value="${license.id}">${license.title}</option>`;
      });

      statesDropdown += '</div></select></fieldset>';
      usersDropdown += '</div></select></fieldset>';
      licenseDropdown += '</div></select></fieldset>';

      newRow.className = 'Table-row';
      newRow.setAttribute('id', `rowContainer_${id}`);
      newRow.innerHTML = `<td class="Table-cell">${licenseDropdown}</td><td class="Table-cell">${statesDropdown}</td>
      <td class="Table-cell"><fieldset class="c-flow c-withLayer"><label class="b-form__inputLabel" for="licenseNumber_${id}">License #</label><input type="text" id="licenseNumber_${id}" class="b-form__inputText c-box" name="licenses[${id}][licenseNumber]"></fieldset></td>
      <td class="Table-cell"><fieldset class="c-flow c-withLayer"><label class="b-form__inputLabel" for="licenseRenewalDate_${id}">License Renewal Date</label><input type="date" id="licenseRenewalDate_${id}" class="b-form__inputText c-box" name="licenses[${id}][licenseRenewalDate]"></fieldset></td>
      <td class="Table-cell">${usersDropdown}</td><td class="Table-cell"><button class="Button-remove Button-left remove-license" type="button" data-id="rowContainer_${id}">${removeIcon}</button></td>`;

      initRemoveRow('.remove-license');
    });
  }
}
