const arrowLeftIcon: () => string = () => {
  return /* html */ `
  <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs><style>.cls-1 { fill: #002538; }</style></defs>
    <path class="cls-1" d="M14.7,9.31H3.02v-2.36H14.7v2.36Z"/>
    <path class="cls-1" d="M.26,8.13L7.97,.23l1.61,1.65L3.48,8.13l6.09,6.25-1.61,1.65L.26,8.13Z"/>
  </svg>`;
};

// eslint-disable-next-line import/prefer-default-export
export { arrowLeftIcon };
