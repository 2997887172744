import { connectGeoSearch } from 'instantsearch.js/es/connectors';
import { numFormatter } from '../../utils/Index';
import { LatLongPos } from '../LeafletMap';
import { AnalyticsWidget } from 'instantsearch.js/es/widgets/analytics/analytics';

interface IRenderGeoSearchOptions {
  items: any;
  widgetParams: any;
}

interface IMapIcon {
  _geoloc: LatLongPos;
  friendlyName?: string;
  price?: number;
  propertyStatus?: string;
  url: string;
  title?: string;
}

let map: any = null;
let markers: any = [];

const propertyResultIcon = ({ price, propertyStatus, url }: IMapIcon) => {
  return /* html */ `
  <a href="${url}">
    <i class="${propertyStatus.toLowerCase()}"></i>
    <span>${numFormatter(price)}</span>
    <i class="arrow">
      <svg viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.263672 6.9548H11.9433V9.31266H0.263672V6.9548Z" />
        <path d="M7.00059 16.0348L5.39039 14.3833L11.4842 8.13434L5.39039 1.88538L7.00059 0.233887L14.7024 8.13437L7.00059 16.0348Z" />
      </svg>
    </i>
  </a>
  `;
};

const agentResultIcon = ({ friendlyName, url }: IMapIcon) => {
  return /* html */ `
  <a href='${url}' class='b-agentMapIndicator c-withIcon'>
    <span>${friendlyName}</span>
    <svg class='b-icon' viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.263672 6.9548H11.9433V9.31266H0.263672V6.9548Z" />
      <path d="M7.00059 16.0348L5.39039 14.3833L11.4842 8.13434L5.39039 1.88538L7.00059 0.233887L14.7024 8.13437L7.00059 16.0348Z" />
    </svg>
  </a>
  `;
};

const mapResultIcon = (iconType: string, data: IMapIcon) => {
  switch (iconType) {
    case 'property':
      return {
        className: 'custom-marker',
        html: propertyResultIcon(data)
      };
    case 'agent':
      return {
        className: 'custom-marker',
        html: agentResultIcon(data)
      };
    default:
      return null;
  }
};

const renderGeoSearch = (
  renderOptions: IRenderGeoSearchOptions,
  isFirstRender: boolean
) => {
  const { items, widgetParams } = renderOptions;
  const { container } = widgetParams;

  if (isFirstRender) {
    // const button = document.createElement('button');
    // button.textContent = 'Clear the map refinement';

    container.style.height = '700px';
    map = window.L.map(container, {
      maxZoom: 15,
      scrollWheelZoom: false,
      zoomControl: false
    }).setView([35.2271, -80.8431], 15);

    // Place ESRI tile(s)
    if (window.L.esri) {
      window.L.esri.Vector.vectorBasemapLayer('ArcGIS:Topographic', {
        apikey: process.env.ESRI_API_KEY
      }).addTo(map);
    }

    window.L.control
      .zoom({
        position: 'bottomright'
      })
      .addTo(map);

    // Hide attribution bar
    const attributionEl = container.querySelector(
      '.leaflet-control-attribution'
    );
    attributionEl.classList.add('u-visually-hidden');

    // container.appendChild(button);
  }

  // Remove existing markers
  map.eachLayer((layer: any) => {
    if (layer && layer instanceof window.L.MarkerClusterGroup) {
      map.removeLayer(layer);
    }
  });

  // Cluster group for clustering
  const clusterGroup: any = window.L.markerClusterGroup();

  markers = items.map((data: IMapIcon) => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { _geoloc, title } = data;
    // Custom Marker
    const iconType = data.friendlyName ? 'agent' : 'property';
    const resultIcon = window.L.divIcon(mapResultIcon(iconType, data));

    const customMarker = window.L.marker([_geoloc.lat, _geoloc.lng], {
      icon: resultIcon,
      title
    });

    // Uncomment to test centering/accuary of custom icons
    // window.L.marker([_geoloc.lat, _geoloc.lng]).addTo(map);
    if (clusterGroup) {
      clusterGroup.addLayer(customMarker);
    }

    return customMarker;
  });
  if (clusterGroup) {
    map.addLayer(clusterGroup);
  }

  // const markerBoundsGroup = window.L.featureGroup(markers);

  if (markers.length) {
    map.fitBounds(window.L.featureGroup(markers).getBounds(), {
      padding: [5, 5]
    });
  }

  setTimeout(() => {
    const listingItems = document.querySelectorAll('.ais-Hits-item');
    if (listingItems) {
      // Attach hover events to listing to interact with map
      Array.from(listingItems).forEach((item: any) => {
        item.addEventListener('mouseenter', function () {
          // get window width
          const width = window.innerWidth;
          if (width > 970) {
            const title = item.querySelector('.b-listingTitle');
            let mapMarker: any = null;
            Array.from(markers).forEach((marker: any) => {
              if (marker.options.title === title.textContent) {
                mapMarker = marker;
              }
            });

            if (mapMarker) {
              // Retrieve the clusters that the marker belongs to
              const clusters = mapMarker.__parent.getAllChildMarkers(); // Get all markers in the cluster

              // Highlight the clusters
              clusters.forEach((clusterMarker: any) => {
                // Get lat and long of the marker
                const lat = clusterMarker._latlng.lat;
                const lng = clusterMarker._latlng.lng;
                // zoom the map to this coordinate
                map.setView(new window.L.LatLng(lat, lng), 12);
              });
            }
          }
        });
        item.addEventListener('mouseleave', function () {
          const width = window.innerWidth;
          if (width > 970) {
            const title = item.querySelector('.b-listingTitle');
            let mapMarker: any = null;
            Array.from(markers).forEach((marker: any) => {
              if (marker.options.title === title.textContent) {
                mapMarker = marker;
              }
            });

            if (mapMarker) {
              // Retrieve the clusters that the marker belongs to
              const clusters = mapMarker.__parent.getAllChildMarkers(); // Get all markers in the cluster
            }
          }
        });
      });
    }
    // Attach event listeners to map markers
    Array.from(markers).forEach((marker: any) => {
      marker.addEventListener('mouseover', function () {
        // look through list of properties and find the one that matches the marker
        Array.from(listingItems).forEach((item: any) => {
          const width = window.innerWidth;
          if (width > 970) {
            const title = item.querySelector('.b-listingTitle');
            if (title.textContent === marker.options.title) {
              // scroll to that property
              item.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
              // add hover state
              item.classList.add('b-listingItem--highlighted');
            }
          }
        });
      });
      marker.addEventListener('mouseout', function () {
       // look through list of properties and find the one that matches the marker
       Array.from(listingItems).forEach((item: any) => {
        const width = window.innerWidth;
        if (width > 970) {
          const title = item.querySelector('.b-listingTitle');
          if (title.textContent === marker.options.title) {
            // remove hover state
            item.classList.remove('b-listingItem--highlighted');
          }
        }
        });
      });
    });
  }, 500);
  
};

const leafletGeoSearch = connectGeoSearch(renderGeoSearch);

export default leafletGeoSearch;
