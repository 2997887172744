// Unlike the querySelector don't include . in your class names
export const queryParents = (el: HTMLElement, selector: string) => {
  while (el.parentNode) {
    el = el.parentNode as HTMLElement;
    if (
      el.tagName === selector ||
      el.classList.contains(selector) ||
      el.tagName === 'BODY'
    )
      return el;
  }
  return null;
};

export default queryParents;
