import { buttonTemplate } from '../templates';
import { propertyFaderTemplate } from './PropertyFader';
import {
  acresTemplate,
  listingTitle,
  checkPropertyPhotos
} from './PropertyListingTemplates';

export interface ILargeScaleLand {
  id: string;
  acres: number;
  image: string;
  listingDate: string;
  photos: string[];
  teaserText: string;
  title: string;
  url: string;
}

export const largeScaleLandListingCard: (data: ILargeScaleLand) => string = ({
  id,
  acres,
  image,
  photos,
  teaserText,
  title,
  url
}) => {
  const allPropertyPhotos = [image, ...photos];
  const propertyPhotos = checkPropertyPhotos(allPropertyPhotos);

  return /* html */ `
  <div class='b-lgScaleLandInvestmentListing | c-switcher'>
    ${propertyFaderTemplate(propertyPhotos, id, url)}
    <div class='b-lgScaleLandInvestmentListing__content'>
      <div class='c-flow | u-flex-align-start'>
        <div class="row flex-row">
          ${listingTitle(title)}
          ${buttonTemplate({
            className: 'b-button--secondary',
            content: 'View Details',
            href: url
          })}
        </div>
        <div class='c-cluster'>
          ${acresTemplate(acres)}
        </div>
        <p>${teaserText}</p>
      </div>
    </div>
  </div>`;
};

export default largeScaleLandListingCard;
