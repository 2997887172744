export const styledPriceTemplate = (price: string, saleType: string) => {
  if (saleType === 'fixedsetPrice') {
    return /* html */ `
      <p class="b-styledPrice">
        <sup class='b-styledPrice__dollarSign'>$</sup>${price}
      </p>
    `;
  } else {
    switch (saleType) {
      case 'bidSale':
        return /* html */ `
          <p class="b-styledPrice">
           Bid Sale
          </p>
        `;
        break;

      case 'pleaseContactBroker':
        return /* html */ `
          <p class="b-styledPrice">
            Contact Broker for Price
          </p>
        `;
        break;

      case 'notYetAvailable':
        return /* html */ `
          <p class="b-styledPrice">
            Price Not Yet Available
          </p>
        `;
        break;

      default:
        return /* html */ `
          <p class="b-styledPrice">
            <sup class='b-styledPrice__dollarSign'>$</sup>${price}
          </p>
        `;
    }
  }
};

export default styledPriceTemplate;
