import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';

class JobsMap {
  parentEl: HTMLElement = null;
  Leaf: any = window.L;
  mapContainer: HTMLElement = null;
  map: any = null;
  markers: any[] = [];  // Array to hold the markers

  constructor(parentEl: HTMLElement) {
    this.parentEl = parentEl;
    this.mapContainer = this.parentEl.querySelector('[data-map]');
  }

  init() {
    this.loadMap();
  }

  loadMap() {
    if (this.mapContainer) {
      this.map = this.Leaf.map(this.mapContainer).setView([39.979232, -95.511029], 4.3);

      this.Leaf.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; OpenStreetMap contributors'
      }).addTo(this.map);

      const dataAttr = this.mapContainer.getAttribute('data-coordinates');
      if (dataAttr) {
        const coordinates = JSON.parse(dataAttr);
        const markers = new this.Leaf.MarkerClusterGroup(); // Create a new marker cluster group

        coordinates.forEach((coord: any) => {
          const customIcon = this.Leaf.divIcon({
            className: 'custom-marker jobs-marker',
            html: `
              <a href="${coord.url || '#'}" target="_blank">
                <span>${coord.cityState || 'Unknown'}</span>
                <i class="arrow">
                  <svg viewBox="0 0 15 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0.263672 6.9548H11.9433V9.31266H0.263672V6.9548Z" />
                    <path d="M7.00059 16.0348L5.39039 14.3833L11.4842 8.13434L5.39039 1.88538L7.00059 0.233887L14.7024 8.13437L7.00059 16.0348Z" />
                  </svg>
                </i>
              </a>
            `
          });

          const marker = this.Leaf.marker([coord.lat, coord.lng], { icon: customIcon });
          markers.addLayer(marker);  // Add marker to cluster group
        });

        this.map.addLayer(markers);  // Add cluster group to map

        const bounds = this.Leaf.latLngBounds(coordinates.map((coord: any) => [coord.lat, coord.lng]));

        // Any event listeners for the map
        this.map.addEventListener(
          'zoomend',
          this.handleMarkerAdjustments.bind(this)
        );
      }
    }
  }

  /**
   * Handles adjusting the marker positioning to be more realistic
   * - Since we're using custom markers the initial placement of them is a bit jacked up
   *
   * @return    {undefined}            Returns nothing, undefined
   */
  handleMarkerAdjustments(): undefined {
    const mapMarkers = Array.from(
      this.mapContainer.querySelectorAll('.custom-marker')
    );
    mapMarkers.forEach((marker: any) => {
      const existingTransform = window.getComputedStyle(marker).transform;
      marker.style.transform = `translateX(-50%) ${existingTransform}`; // eslint-disable-line
    });

    return undefined;
  }
}

export default function initJobsMap() {
  const jobsMapModules: HTMLElement[] = Array.from(
    document.querySelectorAll('[data-job-locations-map]')
  );

  jobsMapModules.forEach((module) => {
    const jobsMapModule = new JobsMap(module);
    jobsMapModule.init();
  });
}
