/**
 * Get Coordinates of a given zip code using our endpoint and DB data
 *
 * @return    {object}             Returns object of lat/lng
 */
export async function getZipCodeCoordinates(zip: string): Promise<any> {
  if (!zip) {
    return undefined;
  }

  const zipLocationResponse = await fetch(
    `/actions/zipcodes/lookup/lat-lng?zipcode=${zip}`
  )
    .then((res) => res.json())
    .then((data) => data);

  let zipCoords = null;
  if (zipLocationResponse.lat && zipLocationResponse.long) {
    // eslint-disable-next-line prefer-destructuring
    zipCoords = zipLocationResponse;
  }

  return zipCoords;
}

/**
 * Adjust default export if we add more map utilities
 */
export default getZipCodeCoordinates;
