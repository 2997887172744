// Sean: Might be a good idea to merge this file with leafletWidget
import { GeoSearchConnectorParams } from 'instantsearch.js/es/connectors/geo-search/connectGeoSearch';
import leafletGeoSearch from './leafletWidget';

const showMap = (
  halfListings: HTMLElement,
  fullListings: HTMLElement,
  map: HTMLElement
) => {
  halfListings.classList.remove('u-box-hidden');
  fullListings.classList.add('u-box-hidden');
  map.classList.remove('u-box-hidden');
};

const hideMap = (
  halfListings: HTMLElement,
  fullListings: HTMLElement,
  map: HTMLElement
) => {
  halfListings.classList.add('u-box-hidden');
  fullListings.classList.remove('u-box-hidden');
  map.classList.add('u-box-hidden');
};

const toggleMap = (showMapUI: boolean) => {
  const map: HTMLElement = document.querySelector('.listingMapColumn');
  const halfListings: HTMLElement = document.querySelector('#listings');
  const fullListings: HTMLElement = document.querySelector('#listingsFull');

  if (showMapUI) {
    showMap(halfListings, fullListings, map);
  } else {
    hideMap(halfListings, fullListings, map);
  }
};

export const initMapUI = (mapToggle: boolean) => {
  const mapToggleButton = document.querySelector('#mapToggleButton');
  if (mapToggleButton) {
    mapToggleButton.addEventListener('click', () => {
      const map = document.querySelector('.listingMapColumn');
      const mapIsClosed = map.classList.contains('u-box-hidden');
      toggleMap(mapIsClosed);
    });
    toggleMap(mapToggle);
  }

  return leafletGeoSearch({
    container: document.querySelector('#map'),
    initialZoom: 15,
    enableRefine: true,
    enableRefineControl: true,
    enableRefineOnMapMove: true,
  } as GeoSearchConnectorParams);
};

export default initMapUI;
