import * as qs from 'qs';

export * from './StateNames';
export * from './QueryParents';

export function bind(
  node: HTMLElement,
  action: string,
  method: (e: Event) => void
): void {
  node.addEventListener(action, method);
}

export function unbind(
  node: HTMLElement,
  action: string,
  method: (e: Event) => void
): void {
  node.removeEventListener(action, method);
}

export const flattenArray = (e: any) => {
  return e.reduce(
    (a: any, b: any) => a.concat(Array.isArray(b) ? flattenArray(b) : b),
    []
  );
};

export function getCurrentQueryString() {
  if (window.location.search) {
    return qs.parse(window.location.search.replace('?', ''));
  }
  return {};
}

export const isLetter = (str: string) => {
  return str.length === 1 && str.match(/[a-z]/i);
};

export function numFormatter(num: number) {
  if (num > 999 && num < 1000000) {
    return `${(num / 1000).toFixed(1)}K`; // convert to K for number from > 1000 < 1 million
  }

  if (num > 1000000) {
    return `${(num / 1000000).toFixed(1)}M`; // convert to M for number from > 1 million
  }

  return num;
}

export const onlySpaces = (str: string) => {
  return str.trim().length === 0;
};

export function pushToQuery(e: Event) {
  const node = e.currentTarget as HTMLInputElement | HTMLSelectElement;
  const key = node.getAttribute('name');
  const { value } = node;
  const queryString = getCurrentQueryString();
  queryString[key] = value;

  window.history.replaceState(
    null,
    null,
    `${window.location.pathname}?${qs.stringify(queryString)}`
  );
}
