/**
 * Fetch to see if there's a user currently logged in.
 *
 * @return    {boolean}             Returns true if logged in, false if not
 */
export async function fetchUserLoginStatus(): Promise<any> {
  const userLoginStatusResponse = await fetch('/api/v1/login-status')
    .then((res) => res.json())
    .then((data) => {
      if (data.loginStatus && data.loginStatus === true) {
        return data.loginStatus;
      }
      return false;
    })
    .catch((err) => {
      if (window.location.href.includes('dev')) {
        console.error(err); // eslint-disable-line
      }
      return false;
    });

  return userLoginStatusResponse;
}

/**
 * Adjust default export if we add more account utilities
 */
export default fetchUserLoginStatus;
