/**
 * Instantsearch Stats Templates
 * @see https://www.algolia.com/doc/api-reference/widgets/stats/js/
 *
 */

export const defaultStatsTemplate = (data: any) => {
  let count = '';

  if (data.hasManyResults) {
    count += `${data.nbHits} results matched your search`;
  } else if (data.hasOneResult) {
    count += '1 result';
  } else {
    count += 'No results';
  }

  return `${count} found`;
  //  in ${data.processingTimeMS}ms
};

export const agentsStatsTemplate = (data: any) => {
  if (data.hasManyResults) {
    return `Showing <span class='u-weight-bold u-t-color-core-secondary'>${data.nbHits}</span> agents`;
  }
  if (data.hasOneResult) {
    return `Found an agent near you`;
  }
  return "Sorry we couldn't find an agent near you.";
};

export const forestersStatsTemplate = (data: any) => {
  if (data.hasManyResults) {
    return `Showing <span class='u-weight-bold u-t-color-core-secondary'>${data.nbHits}</span> foresters`;
  }
  if (data.hasOneResult) {
    return `We found a local forester near you`;
  }
  return "Sorry we couldn't find a forester near you.";
};

export const multiTractStatsTemplate = (data: any) => {
  if (data.hasManyResults) {
    return /* html */ `<span class='u-t-color-core-secondary u-fw-bold'>${data.nbHits}</span> tracts`;
  }
  if (data.hasOneResult) {
    return /* html */ `<span class='u-t-color-core-secondary u-fw-bold'>${data.nbHits}</span> tract`;
  }
  return 'Sorry there are no available properties in this multi-tract';
};

export const getStatsTemplate = (templateName: string, data: any) => {
  switch (templateName) {
    case 'agent':
      return agentsStatsTemplate(data);
    case 'forester':
      return forestersStatsTemplate(data);
    case 'multiTract':
      return multiTractStatsTemplate(data);
    default:
      return defaultStatsTemplate(data);
  }
};

export default getStatsTemplate;
