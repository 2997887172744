import { imageFaderTemplate } from '../ImageFader';
import { spriteIcon } from '../templates';

// This component has a twig version in the basic card
// It may be better named without alluding to properties
export const propertyFaderTemplate = (images: string[], id: string, url: string) => {
  if (!images || images.length === 0) return '';

  return /* html */ `
  <div class='b-propertyFader | c-frame c-withLayer'>
    <div class='c-frame c-withLayer'>
      <button class="favoriteProperty saveListing" data-property-id="${id}">
        <div class="no-favorite"><svg id="Layer_1" width="24" height="23" viewBox="0 0 24 23" fill="none" stroke="#ffffff" xmlns="http://www.w3.org/2000/svg"><path d="M11.9961 1.88353L13.9637 7.93926C14.2315 8.7633 14.9994 9.32122 15.8658 9.32122H22.2332L17.0819 13.0639C16.3809 13.5732 16.0876 14.4759 16.3554 15.2999L18.323 21.3557L13.1717 17.613C12.4707 17.1037 11.5215 17.1037 10.8205 17.613L5.66921 21.3557L7.63684 15.2999C7.90459 14.4759 7.61127 13.5732 6.9103 13.0639L1.75898 9.32122L8.12636 9.32122C8.99281 9.32122 9.76072 8.7633 10.0285 7.93926L11.9961 1.88353Z" /></svg><span>Favorite Property</a></div>
        <div class="favorite">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 27.4 26.2" style="enable-background:new 0 0 27.4 26.2;" xml:space="preserve"><path class="starIcon__fill" d="M13.7,3.7l2,6.1c0.3,0.8,1,1.4,1.9,1.4h6.4l-5.2,3.7c-0.7,0.5-1,1.4-0.7,2.2l2,6.1l-5.2-3.7
          c-0.7-0.5-1.7-0.5-2.4,0l-5.2,3.7l2-6.1c0.3-0.8,0-1.7-0.7-2.2l-5.2-3.7h6.4c0.9,0,1.6-0.6,1.9-1.4L13.7,3.7z"/>
          <path class="starIcon__border" d="M21.9,25.8l-7.6-5.6c-0.4-0.3-0.8-0.3-1.2,0l-7.6,5.6l2.9-9c0.1-0.4,0-0.9-0.4-1.1l-7.6-5.6h9.4
            c0.4,0,0.8-0.3,1-0.7l2.9-9l2.9,9c0.1,0.4,0.5,0.7,1,0.7H27l-7.6,5.6c-0.4,0.3-0.5,0.7-0.4,1.1L21.9,25.8z M6.5,12.1l2.7,1.9
            c1.1,0.8,1.5,2.1,1.1,3.4l-1,3.1l2.7-1.9c1.1-0.8,2.5-0.8,3.5,0l2.7,1.9l-1-3.1c-0.4-1.2,0-2.6,1.1-3.4l2.7-1.9h-3.3
            c-1.3,0-2.5-0.8-2.9-2.1l-1-3.1l-1,3.1c-0.4,1.2-1.5,2.1-2.9,2.1H6.5z"/>
          </svg>
        </div>
      </button>
      <a href='${url}' style="margin: 0; padding: 0; display: block; width: 100%;" title="View this property">
        ${imageFaderTemplate(images)}
      </a>
      <div class='c-layer | u-box-flex u-flex-justify-end'>
        <div class='b-propertyFader__imageIcons | c-box | u-box-flex u-flex-dir-column u-flex-justify-between'>
          <div class='b-photosCountPill | c-withIcon c-box | u-bg-primary-box-text u-t-color-grayscale-100'>
            ${spriteIcon('photo-placeholder')}
            <p>${images.length}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  `;
};

export default propertyFaderTemplate;
