import { fetchUserLoginStatus } from '../utils/Account';

async function addFavoriteProperty(propertyId: string): Promise<any> {
  const token = document.querySelector(
    '[name="CRAFT_CSRF_TOKEN"]'
  ) as HTMLInputElement;

  const params: RequestInit = {
    headers: {
      'content-type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      property: propertyId,
      CRAFT_CSRF_TOKEN: token.value
    }),
    method: 'POST'
  };
  const favoritePropertyResponse = await fetch(
    '/api/v1/properties/favorite',
    params
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.savedProperty) {
        // eslint-disable-next-line no-alert
        window.alert('Property successfully saved');
      }

      return false;
    })
    .catch((err) => {
      if (window.location.href.includes('dev')) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      return false;
    });

  return favoritePropertyResponse;
}

const openModal = () => {
  const modal = document.querySelector('[data-modal-name="favoriteProperty"]');
  if (modal) {
    modal.classList.add('b-modal--open');
  }
};

async function handleSaveProperty(
  button: any,
  propertyId: string
): Promise<any> {
  const userIsLoggedIn = await fetchUserLoginStatus();
  if (userIsLoggedIn) {
    button.addEventListener('click', () => {
      addFavoriteProperty(propertyId);
    });
  } else {
    button.addEventListener('click', openModal);
  }
}

function stopPropagationEvent(element: any): void {
  // The favorite button within the basic card is wrapped in a div that should be a button.
  // The button el is what should have the event listener attached, and then the prevent default should be added to that event listener
  // Or this function needs to be updated to match the tag name to button
  element.addEventListener('click', (event: any) => {
    if (event.target?.tagName === 'use' || event.target?.tagName === 'path') {
      event.preventDefault();
    }
  });
}

export default function init() {
  setTimeout(() => {
    const saveListings = document.querySelectorAll('.saveListing');
    // Need to get a single button for triggering the save property function via JS based on query param (after a user signs in)
    const saveListingButton = document.querySelector('.saveListing');
    const saveFavorites = document.querySelectorAll('.basicCard__star');

    if (saveListings) {
      Array.from(saveListings).forEach((saveListing) => {
        handleSaveProperty(
          saveListing,
          saveListing.getAttribute('data-property-id')
        );
        });
    }

    if (saveFavorites) {
      const cards = document.querySelectorAll('.basicCard__link');
      cards.forEach((card) => {
        stopPropagationEvent(card);
      });
      saveFavorites.forEach((saveFavorite) => {
        handleSaveProperty(
          saveFavorite,
          saveFavorite.getAttribute('data-property-id')
        );
      });
    }

    // Check if the "savesearch" query parameter exists in the current URL
    const urlParams = new URLSearchParams(window.location.search);
    const hasFavoriteQueryParam = urlParams.has('favorite');

    if (saveListingButton) {
      // If the "favorite" query parameter exists, trigger the addFavoriteProperty function
      if (hasFavoriteQueryParam) {
        addFavoriteProperty(saveListingButton.getAttribute('data-property-id'));
      }
    }
  }, 2000);
}
