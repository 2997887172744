/**
 * Imports
 */
import { debounce } from 'lodash';
import LeafletMap from './LeafletMap';

/**
 * PropertySearchMap Class
 */
class PropertySearchMap extends LeafletMap {
  /**
   * Module's top-level element (section)
   */
  parentEl: HTMLElement = null;

  constructor(parentEl: HTMLElement) {
    super();
    this.parentEl = parentEl;
    this.loadingContainer = this.parentEl;
    this.defaultZip = this.parentEl.getAttribute('data-default-zip');
    this.mapContainer = this.parentEl.querySelector('[data-map]');
    this.relativeLocationTextEl = this.parentEl.querySelector(
      '[data-relative-location]'
    );
    this.resultsLengthTextEl = this.parentEl.querySelector(
      '[data-results-count]'
    );
    this.searchInput = this.parentEl.querySelector('[data-zip-input]');
  }

  /**
   * Anything that needs to happen on the initial request
   */
  handleInitialRequest() {
    // We try to query via geolocation first, this method handles fallbacks
    this.searchViaGeolocation();
  }

  /**
   * Method for attaching our event listeners
   */
  attachListeners() {
    this.searchInput.addEventListener(
      'keyup',
      debounce((e: any) => {
        this.handleSearchInputChange(e.target.value);
      }, 1000)
    );
  }

  init() {
    // Initialization and listeners
    this.loadMap();
    this.attachListeners();
    this.handleInitialRequest();
  }
}

/**
 * Initialization
 *
 * @return    {undefined}          returns nothing, initializes Class
 */
export default function initModule() {
  const propertySearchModules: any[] = Array.from(
    document.querySelectorAll('[data-property-search-module]')
  );


  propertySearchModules.forEach((module) => {
    const propertySearchModule: PropertySearchMap = new PropertySearchMap(
      module
    );
    propertySearchModule.init();
  });
}
