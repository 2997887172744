export interface IImage {
  alt?: string;
  src: string;
}

export const imageTemplate = (src: string, alt: string) => {
  if (!src) {
    return '';
  }

  const altAttr = alt ? `alt='${alt}'` : '';

  return /* html */ `
    <div class='c-frame'>
      <img src='${src}' ${altAttr} />
    </div>`;
};

export default imageTemplate;
