const initSwitches = () => {
  const switches = document.querySelectorAll('.b-switch');
  if (switches) {
    switches.forEach((el) => {
      el.addEventListener('click', (e) => {
        const target = e.target as HTMLElement;
        const switchEl = target.classList.contains('b-switch')
          ? target
          : target.parentElement;
        const isOn = switchEl.classList.contains('b-switch--on');
        if (isOn) {
          switchEl.classList.remove('b-switch--on');
        } else {
          switchEl.classList.add('b-switch--on');
        }
      });
    });
  }
};

export default initSwitches;
