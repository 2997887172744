/**
 * Get Zip code of given lat longitude
 *
 * @return      {string}            Returns a string that is the nearest zipcode
 */
export async function getCoordinatesZipCode(
  lat: number,
  long: number
): Promise<any> {
  if (!lat || !long) {
    return undefined;
  }

  const zipcodeObj = await fetch(
    `/actions/zipcodes/lookup/zipcode?lat=${lat}&lng=${long}`
  )
    .then((res) => res.json())
    .then((data) => data);

  // TODO: replace this temp simple return with actual value
  // const zipcode = '30678';

  return zipcodeObj.zipcode;
}

export default getCoordinatesZipCode;
