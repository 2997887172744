// import {  } from './'

import { buttonTemplate, imageTemplate } from '../templates';
import { acresTemplate, listingTitle } from './PropertyListingTemplates';

export interface IMultiTract {
  id: string;
  acres: number;
  altText: string;
  image: string;
  location: string;
  teaserText: string;
  title: string;
  url: string;
}

export const multiTractListing: (data: IMultiTract) => string = ({
  id,
  acres,
  altText,
  image,
  location,
  teaserText,
  title,
  url
}) => {
  return /* html */ `
  <style scoped>
    .b-multiTract-row {
      display: flex;
      flex-direction: row;
    }
    .b-multiTract-subrow {
      display: flex;
      flex-direction: row;
      justify-content: space-between; 
      width: 100%; 
      padding-bottom: 15px;
    }
    .w-1\\/3 {
      width: 33.333333%;
    }
    .w-2\\/3 {
      width: 66.666667%;
      padding-left: 80px;
    }
    .b-multiTract-button {
      height: 30px;
    }
    .b-multiTract-location {
      padding-bottom: 5px; 
      font-size: 14px; 
      max-width: calc(100% - 180px);
    }
    @media screen and (max-width: 768px) {
      .b-multiTract-row {
        flex-direction: column;
      }
      .b-multiTract-row img {
        margin-bottom: 45px;
      }
      .b-multiTract-location {
        max-width: 100%;
      }
      .w-1\\/3 {
        width: 100%;
      }
      .w-2\\/3 {
        width: 100%;
        padding-left: 0;
      }
    }
  </style>
  <div class='b-multiTract'>
    <div class='b-multiTract-row'>
      <div class="w-1/3">
        ${imageTemplate(image, altText)}
      </div>
      <div class="w-2/3">
        <div class='b-multiTract-subrow'>
          <div>
            ${listingTitle(title)}
            ${acresTemplate(acres)}
          </div>
          <div class="b-multiTract-button">
            ${buttonTemplate({
              className: 'b-button--secondary',
              href: url,
              content: 'View Details'
            })}
          </div>
        </div>
        <div class="b-multiTract-location">${location}</div>
        <p>${teaserText}</p>
      </div>
    </div>
  </div>`;
};

export default multiTractListing;
