import { set } from 'lodash';
import { fetchUserLoginStatus } from '../utils/Account';

async function addSearch(searchUrl: string, searchTitle: string): Promise<any> {
  console.log('addSearch', searchUrl, searchTitle);
  const token = document.querySelector(
    '[name="CRAFT_CSRF_TOKEN"]'
  ) as HTMLInputElement;

  const params: RequestInit = {
    headers: {
      'content-type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      searchUrl,
      searchTitle,
      CRAFT_CSRF_TOKEN: token.value
    }),
    method: 'POST'
  };
  const favoritePropertyResponse = await fetch(
    '/api/v1/properties/save-search',
    params
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.savedSearch) {
        return true;
      }

      return false;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });

  return favoritePropertyResponse;
}

async function removeSearch(searchIndex: string): Promise<any> {
  const token = document.querySelector(
    '[name="CRAFT_CSRF_TOKEN"]'
  ) as HTMLInputElement;

  const params: RequestInit = {
    headers: {
      'content-type': 'application/json; charset=UTF-8'
    },
    body: JSON.stringify({
      searchIndex,
      CRAFT_CSRF_TOKEN: token.value
    }),
    method: 'POST'
  };
  const favoritePropertyResponse = await fetch(
    '/api/v1/properties/remove-search',
    params
  )
    .then((res) => res.json())
    .then((data) => {
      if (data.removedSearch) {
        return true;
      }

      return false;
    })
    .catch((err) => {
      if (window.location.href.includes('dev')) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
      return false;
    });

  return favoritePropertyResponse;
}

const openModal = () => {
  const modal = document.querySelector('.b-modal');
  modal.classList.add('b-modal--open');
};

const saveUserSearch = () => {
  const searchTitle = document.getElementById(
    'searchName'
  ) as HTMLInputElement;
  const requestResponse = addSearch(
    `${window.location.pathname}${window.location.search}`,
    searchTitle.value
  );

  if (requestResponse) {
    const modalNameSaved = document.querySelector('.b-modal .b-modal__searchSaved');
    const searchForm = document.querySelector('.b-modal .b-modal__nameSearch');
    if (searchForm) {
      // hide the form
      searchForm.classList.add('is--hidden');
    }
    if (modalNameSaved) {
      // show the elements
      modalNameSaved.classList.remove('is--hidden');
    }
    setTimeout(() => {
      // close the modal
      const modal = document.querySelector('.b-modal');
      modal.classList.remove('b-modal--open');
      // clear the input field
      searchTitle.value = '';
    }, 5000);
  };
};

const removeUserSearch = () => {
  const deleteButtons = document.querySelectorAll('.remove-search');

  if (deleteButtons) {
    deleteButtons.forEach((button: HTMLElement) => {
      button.addEventListener('click', () => {
        const index = button.getAttribute('data-search-id');
        const requestResponse = removeSearch(index);

        if (requestResponse) {
          // TODO: this should be a modal, or ideally a notification block rather than a window alert.
          // eslint-disable-next-line no-alert
          window.alert('Search removed');
          button.parentElement.parentElement.remove();
          const searchNumber = document.querySelectorAll('.Table-row').length;
          document.getElementById(
            'savedSearchesHeading'
          ).innerHTML = `Saved Searches (${searchNumber})`;
        }
      });
    });
  }
};

const nameSearch = () => {
  const modalNameForm = document.querySelector('.b-modal .b-modal__nameSearch');
  if (modalNameForm) {
    // show the form
    modalNameForm.classList.remove('is--hidden');
  }
  openModal();
  // Attach event listener to the save button
  const saveButton = document.querySelector('.b-modal .b-modal__nameSearch button');
  if (saveButton) {
    saveButton.addEventListener('click', saveUserSearch);
  }
}

async function handleSaveSearch(button: HTMLElement): Promise<any> {
  const userIsLoggedIn = await fetchUserLoginStatus();
  if (userIsLoggedIn) {
    //button.addEventListener('click', saveUserSearch);
    button.addEventListener('click', nameSearch);
  } else {
    button.addEventListener('click', function () {
      // Get the current full URL with query params
      const currentUrl = window.location.href;
      let strippedUrl = currentUrl.replace(/.*\.com/, '');
      if (strippedUrl.includes('?')) {
        strippedUrl = strippedUrl + '&savesearch=true';
      } else {
        strippedUrl = strippedUrl + '?savesearch=true';
      }
    
      // Encode the URL as a query parameter
      const encodedUrl = encodeURIComponent(strippedUrl);
    
      // Create an XMLHttpRequest object
      const xhr = new XMLHttpRequest();
      const url = '/hashurl?url=' + encodedUrl;
    
      // Configure the request
      xhr.open('GET', url, true);
    
      // Define a callback function for when the request is complete
      xhr.onload = function () {
        if (xhr.status === 200) {
          // Find the modal form redirect
          const modalForm = document.querySelector('.b-modal form');
          if (modalForm) {
            // show the form
            const container = document.querySelector('.b-modal .b-modal__loginForm');
            container.classList.remove('is--hidden');
            // remove the current input field named "redirect" and replace with the data returned from the xhr request
            const redirectInput = modalForm.querySelector('input[name="redirect"]');
            if (redirectInput) {
              redirectInput.remove();
            }
            // the xhr response returns an html input element. add this element to the inside of the form
            const input = xhr.response;
            modalForm.insertAdjacentHTML('beforeend', input);
          }
          openModal();
        } else {
          console.error('Error:', xhr.status);
        }
      };
    
      // Send the request
      xhr.send();
    });
    
  }
}

export default function init() {
  const saveSearch = document.getElementById('saveSearch');
  const listing = document.getElementById('savedSearchesListing');

   // Check if the "savesearch" query parameter exists in the current URL
   const urlParams = new URLSearchParams(window.location.search);
   const hasSaveSearchQueryParam = urlParams.has('savesearch');

  if (saveSearch) {
    handleSaveSearch(saveSearch);

    // If the "savesearch" query parameter exists, trigger the nameSearch function
    if (hasSaveSearchQueryParam) {
      // Wait for the page to fully load before triggering the nameSearch function
      setTimeout(() => {
        nameSearch();
      }, 1000);
    }

  }
  if (listing) removeUserSearch();
}
