import { spriteIcon } from '../templates';
import { buttonTemplate } from '../templates/Button';
import { listingTitle } from './PropertyListingTemplates';

export interface IAgentListing {
  cellPhone: string;
  firstName: string;
  lastName: string;
  location: string;
  officePhone: string;
  photoUrl: string;
  url: string;
  userTitle: string;
  contactUrl: string;
}

const phoneLink = (phoneNumber: string, iconName: string) => {
  if (!phoneNumber || phoneNumber === 'N/A') {
    return '';
  }
  const numbers = phoneNumber.split('.').join('');
  return /* html */ `
  <a href='tel:+${numbers}'>
    ${spriteIcon(iconName, 'u-t-color-core-secondary')}
    <span class='u-t-color-grayscale-500'>${phoneNumber}</span>
  </a>
  `;
};

export const agentListing: (
  data: IAgentListing,
  useForester?: boolean
) => string = (
  {
    cellPhone,
    location,
    firstName,
    lastName,
    officePhone,
    photoUrl,
    url,
    userTitle,
    contactUrl
  },
  useForester
) => {
  const userTitleEl = userTitle
    ? `<p class='b-agentListing__userTitle u-t-color-core-tertiary'>${userTitle}</p>`
    : '';
  const officePhoneEl = phoneLink(officePhone, 'officePhone');
  const cellPhoneEl = phoneLink(cellPhone, 'mobilePhone');
  const fullName = `${firstName} ${lastName}`;
  const bottomLeftElement = buttonTemplate({
        tagType: 'a',
        className: 'b-agentListing__viewProfile',
        href: url,
        iconName: 'profile',
        content: 'View Profile'
      });

  return /* html */ `
  <div class='b-agentListing | c-cluster'>
    <div class='b-agentListing__image c-frame c-frame--aspect-1-1'>
      <img src='${photoUrl}' />
    </div>
    <div class='b-agentListing__content | c-flow'>
      <div class='c-repel'>
        <div class='c-flow u-flow-space-100'>
          <a href="${url}" title="View profile">${listingTitle(fullName, 'b-agentListing__name')}</a>
          ${userTitleEl}
        </div>
        <div class='b-agentListing__phones c-flow'>
          ${cellPhoneEl}
          ${officePhoneEl}
        </div>
      </div>
      <div class='c-repel'>
        ${bottomLeftElement}
        ${
          contactUrl !== null
            ? buttonTemplate({
                tagType: 'a',
                className:
                  'b-button--secondary  b-button--clear  b-button--with-icon',
                href: `${contactUrl}`,
                iconName: 'chatbox',
                content: `Contact ${firstName}`
              })
            : ''
        }
      </div>
    </div>
  </div>`;
};

export default agentListing;
