import {Sticky} from 'sticky-ts';
const sidebarElement = document.querySelector('.sidebar-agents-wrapper') as HTMLElement;
const modalImageGrid = document.querySelector('.b-imageGrid');
const modalCards = document.querySelectorAll('[data-trigger-large]');
const modalLarge = document.querySelector('.image-large');
const imageLargeModalClose = document.getElementById('image-large-close');
if (sidebarElement) {
  let sticky = new Sticky('.sidebar-agents-wrapper', {
    'wrap': true
  });
}

function clearActive(anchors: HTMLAnchorElement[]) {
  anchors.forEach((anchor) => anchor.removeAttribute('data-active'));
}

function setup(node: HTMLElement) {
  const swiperUuid = node.getAttribute('data-phc');
  if (!swiperUuid) return;

  const swiperInstance = window.swipers.find(
    (i) => i.uuid === swiperUuid
  )?.swiper;
  if (!swiperInstance) return;

  const availableStartPoints: HTMLAnchorElement[] = Array.from(
    node.querySelectorAll<HTMLAnchorElement>('[data-phc-start]')
  );

  availableStartPoints.forEach((anchor) => {
    anchor.addEventListener('click', (e) => {
      // no clickity please
      e.preventDefault();
      clearActive(availableStartPoints);
      anchor.setAttribute('data-active', 'true');
      const slideTo = Number(anchor.getAttribute('data-phc-start') ?? '0');
      swiperInstance.slideTo(slideTo);
    });
  });

  const previousUrl = document.referrer;
  const urlPieces = previousUrl.split(/[/?]/);
  if (urlPieces[3] === 'properties') {
    const backToSearch: HTMLAnchorElement = document.getElementById(
      'backToSearch'
    ) as HTMLAnchorElement;
    backToSearch.href = previousUrl;
  }

  /**
   * Listen to swiper changes to activate the proper anchor tag
   * and ensuring it works in both directions
   */
  swiperInstance.on('slideChange', (swiper) => {
    const activeAnchorStart = node.querySelector(
      `[data-phc-start="${swiper.activeIndex}"]`
    );
    const activeAnchorEnd = node.querySelector(
      `[data-phc-end="${swiper.activeIndex}"]`
    );
    const carouselWrapper: HTMLElement = document.querySelector(
      '[data-last-photo-index]'
    );
    const { lastPhotoIndex } = carouselWrapper.dataset;
    const lastPhotoIndexNum = parseInt(lastPhotoIndex, 10);
    const showAllPhotosButton: HTMLElement = document.querySelector(
      '#propertyDetail__imageGridButton'
    );
    if (swiper.activeIndex > lastPhotoIndexNum) {
      showAllPhotosButton.style.display = 'none';
    } else {
      showAllPhotosButton.style.removeProperty('display');
    }
    if (!activeAnchorStart && !activeAnchorEnd) return;
    clearActive(availableStartPoints);
    (activeAnchorStart || activeAnchorEnd).setAttribute('data-active', 'true');
  });

  // Listen for clicks on all modalCards, grab the data attribute and show the large corresponding image
  modalCards.forEach((card) => {
    card.addEventListener('click', (e) => {
      e.preventDefault();
      const index = card.getAttribute('data-trigger-large');
      modalLarge.classList.add('is-active');
      modalImageGrid.classList.add('image-large-active');
      imageLargeModalClose.classList.add('is-active');
      const toOpen = document.querySelector(`[data-source-index="${index}"]`);
      if (toOpen) {
        toOpen.classList.add('is-active');
      }
    });
  });
  imageLargeModalClose.addEventListener('click', () => {
    modalLarge.classList.remove('is-active');
    modalImageGrid.classList.remove('image-large-active');
    imageLargeModalClose.classList.remove('is-active');
    const activeImage = document.querySelector('.is-active');
    if (activeImage) {
      activeImage.classList.remove('is-active');
    }
  });

}

export default function init(): void {
  const propertyDetailHero = document.querySelector('.b-propertyDetailHero');
  if (propertyDetailHero) {
    const propertyDetailHeroHeader: HTMLElement =
      propertyDetailHero.querySelector('.b-propertyDetailHero__header');
    const backButton: HTMLElement = propertyDetailHero.querySelector(
      '.b-propertyDetailHero__backbutton'
    );

    if (backButton) {
      document.addEventListener('scroll', () => {
        const { scrollY } = window;
        // const backButtonHeight: number = backButton.offsetHeight;
        const transformHeight = 56;
        if (scrollY > 20) {
          propertyDetailHeroHeader.style.transform = `translateY(-${transformHeight}px)`;
        } else {
          propertyDetailHeroHeader.style.removeProperty('transform');
        }
      });
    }
  }

  const propertyDetailHeroCarousel: HTMLElement[] = Array.from(
    document.querySelectorAll<HTMLElement>('[data-phc]')
  );

  propertyDetailHeroCarousel.forEach((module) => setup(module));
}
