export const toggleResetBtn = (btn: HTMLElement, hide: boolean) => {
  if (hide) {
    btn.style.removeProperty('display');
    return;
  }
  btn.style.display = 'flex';
};

const initInputs = () => {
  const textInputs = document.querySelectorAll('.b-inputText');

  if (textInputs) {
    const testValue = (value: string, regExStr: string) => {
      const regEx = new RegExp(regExStr);
      return regEx.test(value);
    };

    const hideIncomplete = (incompleteIcon: HTMLElement) => {
      incompleteIcon.style.removeProperty('display');
    };

    const showComplete = (
      completeIcon: HTMLElement,
      incompleteIcon: HTMLElement
    ) => {
      hideIncomplete(incompleteIcon);
      completeIcon.style.display = 'block';
    };

    const showIncomplete = (
      completeIcon: HTMLElement,
      incompleteIcon: HTMLElement
    ) => {
      completeIcon.style.removeProperty('display');
      incompleteIcon.style.display = 'block';
    };

    textInputs.forEach((inputUi) => {
      const input: HTMLInputElement = inputUi.querySelector('input');
      const resetBtn: HTMLElement = inputUi.querySelector('.b-input__control');
      const togglePasswordVisibilityBtn: HTMLElement =
        inputUi.querySelector('.b-input__password');
      const incompleteIcon: HTMLElement = inputUi.querySelector(
        '.b-input__incomplete'
      );
      const completeIcon: HTMLElement =
        inputUi.querySelector('.b-input__complete');

      input.addEventListener('change', () => {
        const { value, pattern } = input;
        if (value === '') {
          toggleResetBtn(resetBtn, true);
          showIncomplete(completeIcon, incompleteIcon);
        } else {
          const isValid = testValue(value, pattern);
          toggleResetBtn(resetBtn, false);
          if (isValid) {
            if (pattern !== '') {
              showComplete(completeIcon, incompleteIcon);
              return;
            }
            hideIncomplete(incompleteIcon);
            return;
          }
          incompleteIcon.style.display = 'block';
        }
      });

      input.addEventListener('beforeinput', (e) => {
        if (e.data !== null) {
          toggleResetBtn(resetBtn, false);
        } else {
          toggleResetBtn(resetBtn, true);
        }
        incompleteIcon.style.removeProperty('display');
        completeIcon.style.removeProperty('display');
      });

      resetBtn.addEventListener('click', () => {
        input.value = '';
        resetBtn.style.removeProperty('display');
        showIncomplete(completeIcon, incompleteIcon);
      });

      // #region - Password input
      if (togglePasswordVisibilityBtn) {
        togglePasswordVisibilityBtn.addEventListener('click', () => {
          // input.type = input.type === 'password' ? 'text' : 'password';
          const showPasswordIcon: TSVGInHTML = inputUi.querySelector(
            '.b-input__showPassword'
          );
          const hidePasswordIcon: TSVGInHTML = inputUi.querySelector(
            '.b-input__hidePassword'
          );
          if (input.type === 'password') {
            input.type = 'text';
            hidePasswordIcon.style.display = 'block';
            showPasswordIcon.style.display = 'none';
            return;
          }
          input.type = 'password';
          hidePasswordIcon.style.removeProperty('display');
          showPasswordIcon.style.removeProperty('display');
        });
      }
      // #endregion - Password input
    });
  }
};

export default initInputs;
