import initAccount from './modules/Account';
import initFavoriteProperties from './modules/FavoriteProperties';
import initHeader from './modules/Header';
import { initImageFader } from './modules/ImageFader';
import initInputs from './modules/Input';
import initListingUI from './modules/InstantSearch';
import initModal from './modal';
import initCloseButton from './close';
import initJobsMap from './modules/JobsMap';
import initNavAccountLinks from './modules/NavAccountLinks';
import initPropertyDetailHero from './modules/PropertyDetailHero';
import initPropertySearchMaps from './modules/PropertySearchMap';
import initSavedSearches from './modules/SavedSearches';
import initSwiperCarousels from './modules/SwiperCarousels';
import initSwitches from './modules/SwitchTemplate';
import { initVideoControllers, initHeroSearch } from './modules';
import initContactFreeForms from './modules/ContactFreeForms';
import initMapButton from './maps';

const App = (): void => {
  initSwiperCarousels();
  initImageFader();
  initAccount();
  initHeader();
  initHeroSearch();
  initListingUI();
  initModal();
  initCloseButton();
  initJobsMap();
  initNavAccountLinks();
  initPropertyDetailHero();
  initPropertySearchMaps();
  initFavoriteProperties();
  initSavedSearches();
  initSwitches();
  initInputs();
  initVideoControllers();
  initContactFreeForms();
  initMapButton();
};

document.addEventListener('DOMContentLoaded', () => {
  App();
});
