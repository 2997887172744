const initCloseButton = () => {
  const btn = document.querySelector('.hero-property-card__closeBtn');

  if (btn) {
    btn.addEventListener('click', (ev) => {
      ev.preventDefault();
      const card = document.querySelector('.hero-property-card') as HTMLElement;
      card.style.display = 'none';
    });
  }
};

export default initCloseButton;
