import {
  propertyListingHalfCard,
  IPropertyListing,
  propertyListingFullCard
} from './PropertyListingTemplates';

export interface IMultiTractProperties {
  properties: IPropertyListing[];
}

export const multiTractProperties: (
  data: IMultiTractProperties,
  templateFunction: (subData: IPropertyListing) => string
) => string = ({ properties }, templateFunction) => {
  let listings = '';
  properties.forEach((property) => {
    listings = `${listings}${templateFunction(property)}`;
  });
  return /* html */ `
    <div>
      ${listings}
    </div>
  `;
};

export const multiTractPropertiesHalfCards: (
  data: IMultiTractProperties
) => string = (data) => {
  return multiTractProperties(data, propertyListingHalfCard);
};

export const multiTractPropertiesFullCards: (
  data: IMultiTractProperties
) => string = (data) => {
  return multiTractProperties(data, propertyListingFullCard);
};

export default multiTractProperties;
