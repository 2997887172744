const handleFormMessageRender = (element: HTMLElement, data: any) => {
  /*
    Create the following markup:
    <div class="b-formMessage c-box u-hidden-yes u-gap-bottom-500">
	    <h3 class="u-text-4">{# Set the Success text in CMS #}</h3>
	  </div>
  */
  const msg: HTMLElement = document.createElement('header');
  msg.classList.add(
    'b-formMessage',
    'c-box',
    'u-hidden-yes',
    'u-gap-bottom-500',
    data.freeform.options.successClassBanner
  );
  const heading: HTMLElement = document.createElement('h3');
  heading.classList.add('u-text-4');
  // Success text is retrieved from freeform options obj
  heading.innerText = data.freeform.options.successBannerMessage;

  msg.append(heading);
  element.prepend(msg);
};

const handleFreeformReturnMessages = (element: HTMLElement) => {
  const html: string = element.innerHTML;

  document.addEventListener('freeform-ajax-success', (event) => {
    event.preventDefault();
    element.innerHTML = html;

    const msg: HTMLElement = document.querySelector('.b-formMessage');
    msg.classList.remove('u-hidden-yes');

    // Offset based on stick header height
    const y = document
      .querySelector('.b-header')
      .getBoundingClientRect().height;

    event.freeform.options.scrollOffset = -(y + 30);
  });
};

export default function initContactFreeForms() {
  const form: HTMLElement = document.querySelector('[data-freeform]');
  // Check if we have a freeform on the page
  if (form)
    form.addEventListener('freeform-ready', (event) => {
      handleFormMessageRender(form, event);
      handleFreeformReturnMessages(form);
    });
}
